import { createAction } from 'redux-actions';
import type { AddDialogStatisticCommand } from '@/generated-api/index.js';

export namespace statisticActions {
  export const enum Type {
    POST_STATISTICS = 'POST_STATISTICS',
    POST_DIALOG_STATISTICS_SUCCESS = 'POST_DIALOG_STATISTICS_SUCCESS',
    POST_DIALOG_STATISTICS_FAILURE = 'POST_DIALOG_STATISTICS_FAILURE'
  }
  export const postDialogStatisticsSuccess = createAction<any>(
    statisticActions.Type.POST_DIALOG_STATISTICS_SUCCESS
  );
  
  export const postDialogStatisticsFailure = createAction<Error>(
    statisticActions.Type.POST_DIALOG_STATISTICS_FAILURE
  );

  export const stopStatistic = createAction<AddDialogStatisticCommand>(Type.POST_STATISTICS);
}