import type { FC } from 'react';
import React from 'react';
import type { ClassNameMap } from '@material-ui/core/styles/withStyles.js';
import { List, Collapse, Grid } from '@material-ui/core';

import type { SceneDto, SceneGroupDto } from '@/generated-api/index.js';
import type { SceneGroupListItemProps } from 'app/components/NavigationDrawer/ScenarioSection/NavigationDrawerScenarioList/SceneGroupListItem.js';
import SceneGroupListItem from 'app/components/NavigationDrawer/ScenarioSection/NavigationDrawerScenarioList/SceneGroupListItem.js';

import SceneGroupContainerListItem from './SceneGroupContainerListItem.js';
import type { SceneGroupWithScenes } from './types.js';

interface SceneGroupContainerProps extends SceneGroupListItemProps {
  classes: ClassNameMap;
  sceneId: number | null;
  sceneGroup: SceneGroupWithScenes;
  openSceneGroups: number[];
  handleSelectScenario: () => void;
  renderContextMenu: (sceneGroupId: number) => JSX.Element;
  handleClickOnGroupSubmenu: (sceneGroupId: number) => void;
  handleRightClick: (event: React.MouseEvent<HTMLElement>, scene: SceneDto) => void;
}

const SceneGroupContainer: FC<SceneGroupContainerProps> = ({
  classes,
  sceneId,
  sceneGroup,
  handleRightClick,
  renderContextMenu,
  openSceneGroups,
  handleSelectScenario,
  handleClickOnGroupSubmenu,
  isSubGroup
}): JSX.Element => {
  // Determine if this group is expanded
  const isOpen = openSceneGroups.includes(sceneGroup.id);

  return (
    <Grid key={'sceneGroupList' + sceneGroup.id}>
      <SceneGroupListItem
        classes={classes}
        sceneGroup={sceneGroup}
        isSubGroup={isSubGroup}
        openSceneGroups={openSceneGroups}
        handleClickOnGroupSubmenu={handleClickOnGroupSubmenu}
      />
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" data-cy="listForScnario" disablePadding>
          {sceneGroup.subGroups?.map((subGroup) => (
            <SceneGroupContainer
              key={subGroup.id}
              classes={classes}
              sceneId={sceneId}
              openSceneGroups={openSceneGroups}
              handleSelectScenario={handleSelectScenario}
              handleClickOnGroupSubmenu={handleClickOnGroupSubmenu}
              handleRightClick={handleRightClick}
              renderContextMenu={renderContextMenu}
              sceneGroup={subGroup}
              isSubGroup
            />
          ))}
          {sceneGroup.scenes.map((scene) => (
            <SceneGroupContainerListItem
              scene={scene}
              key={scene.id}
              classes={classes}
              handleRightClick={handleRightClick}
              handleSelectScenario={handleSelectScenario}
              sceneId={sceneId}
              isSubGroupItem={true}
              disableActions={sceneGroup.disableActions}
            />
          ))}
          {renderContextMenu(sceneGroup.id)}
        </List>
      </Collapse>
    </Grid>
  );
};

export default SceneGroupContainer;