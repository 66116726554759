import type { Theme } from '@material-ui/core/styles/index.js';
import { makeStyles, createStyles } from '@material-ui/core/styles/index.js';

export default makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      background: '#162a35'
      // height: 50
    },
    appbarOffset: theme.mixins.denseToolbar,
    hide: {
      display: 'none'
    },
    headerToolbar: {
      paddingBottom: 2
    },
    logo: {
      height: 20,
      width: 59,
      backgroundImage: 'url(https://scenarioeditorstdev.blob.core.windows.net/public/white_logo.png)',
      backgroundPosition: 'center center',
      backgroundSize: 'cover'
    },
    bigLogo: {
      height: 103,
      width: 285,
      marginRight: -25,
      backgroundImage: 'url(https://scenarioeditorstdev.blob.core.windows.net/public/sklls_logo.png)',
      backgroundPosition: 'center center',
      backgroundSize: 'cover'
    },
    headerToolbarLink: {
      textDecoration: 'none',
      '&:visited, &:hover, &:active': {
        color: 'white',
        textDecoration: 'none'
      }
    },
    headerToolbarTitle: {
      color: 'white',
      fontSize: 12,
      display: 'block'
    },
    titleLogin: {
      color: 'black',
      fontSize: 32,
      display: 'block'
    },
    titleLoginGridItem: {
      marginBottom: '50px'
    },
    iconIsleWidth: {
      width: 276
    },
    whiteIcon: {
      color: 'white'
    },
    signOffBtn: {
      color: 'white'
    },
    maxWidth: {
      maxWidth: 276
    },
    headerToolsWidth: {
      width: 'calc(100% - 276px)'
    },
    companyNamePadding: {
      padding: '6px 8px'
    },
    userAvatar: {
      height: 35,
      width: 35
    }
  })
);
