/* eslint-disable react/display-name */
import React, { useMemo, useState } from 'react';
import { Box, Button, Grid, Paper, TextField, Fab } from '@material-ui/core';
import type { GridSelectionModel } from '@material-ui/data-grid';
import { GridToolbarExport } from '@material-ui/data-grid';
import { Delete, Edit, HomeWork, Settings, Add, PersonAdd, Search } from '@material-ui/icons';


import SimpleDataGrid from 'app/components/SimpleDataGrid.js';
import useTableData from 'app/components/UsersPage/UserTable/hooks/useTableData.js';
import useStyles from 'app/components/DashboardPage/Table/components/kpiFiltersStyle.js';
import useUsersStyles from 'app/components/UsersPage/styles.js';
import useDebouncedValue from 'app/hooks/useDebouncedValue.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nUserManagementNs } from '@/i18n/dictionaries/interfaces.js';
import { isUserAnyAdminSelector, isUserOwnerSelector, isUserSuperAdminSelector } from 'app/selectors/userSelectors.js';
import { useSelector } from 'react-redux';
import { UserManagementSelectors } from 'app/selectors/index.js';

type UsersTableProps = {
  onDelete: (id: number) => void;
  onSelect: (id: number) => void;
  openUserInviteDialog: () => void;
  openDepartmentDialog: () => void;
  openAdvancedSettingsDialog: () => void;
  selectionModel: GridSelectionModel;
  setSelectionModel: (newModel: GridSelectionModel) => void;
  openUsersDeleteDialog: () => void;
};

const defaultRowsPerPage = 25;

const UsersTable: React.FC<UsersTableProps> = ({
  onSelect,
  onDelete,
  openUserInviteDialog,
  openDepartmentDialog,
  openAdvancedSettingsDialog,
  selectionModel,
  setSelectionModel,
  openUsersDeleteDialog
}) => {
  const { rows, columns, loading } = useTableData({ onEdit: onSelect, onDelete });
  const [search, setSearch] = useState<string>('');
  const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
  const classes = useStyles();
  const [translate] = useTranslation([I18nNamespace.UserManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  // const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const searchDebounced = useDebouncedValue(search, 400);

  const handleChangeRowsPerPage = (value: number) => {
    setRowsPerPage(value);
  };

  const userClasses = useUsersStyles();

  const isUserAnyAdmin = useSelector(isUserAnyAdminSelector);
  const isUserOwner = useSelector(isUserOwnerSelector);
  const isSuperadmin = useSelector(isUserSuperAdminSelector);
  const users = useSelector(UserManagementSelectors.users);

  const editingUsers = useMemo(() => {
    if (!selectionModel.length) return [];
    return users.filter((u) => selectionModel.some((id) => id === u.id));
  }, [users, selectionModel]);

  const editingUsersCompanyId = useMemo(() => {
    if (!editingUsers.length) return null;
    const first = editingUsers[0].companyId;
    return editingUsers.every((u) => u.companyId === first) ? first : null;
  }, [editingUsers]);

  // TODO Move search to backend? (and add pagination?)

  const rowsFiltered = useMemo(() => {
    if (!searchDebounced) return rows;

    // Filter on name/email/organization
    return rows.filter((row) => {
      const searchLower = searchDebounced.toLowerCase();
      const name = row.name?.toLowerCase();
      const email = row.email?.toLowerCase();
      const companyName = row.companyName?.toLowerCase();

      if (searchLower === name || searchLower === email || searchLower === companyName) {
        return true;
      }

      if (name?.includes(searchLower) || email?.includes(searchLower) || companyName?.includes(searchLower)) {
        return true;
      }

      return false;
    });
  }, [searchDebounced, rows]);

  return (
    <Box>
      <Grid container justifyContent="space-between" alignItems="center" className={classes.container} style={{ marginTop: 30, marginBottom: 16 }}>
        <Grid item>
          <TextField
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            placeholder={translate(nameof.full<I18nUserManagementNs>((n) => n.search))}
            variant="outlined"
            size='small'
            InputProps={{
              startAdornment: <Search style={{ color: 'rgba(0, 0, 0, 0.54)', marginRight: 8 }} />,
              className: classes.searchInput,
              
            }}
          />
        </Grid>
        <Grid item style={{marginRight: 25}}>
                    <Fab color="primary" aria-label="add" onClick={openUserInviteDialog} variant="extended" style={{ paddingLeft: 16, textTransform: 'none' }}>
                      <PersonAdd style={{ marginRight: 8 }} />
                      {translate(nameof.full<I18nUserManagementNs>((n) => n.inviteUsers))}
                    </Fab>
                  </Grid>
      </Grid>
      <SimpleDataGrid
        selectionModel={selectionModel}
        onSelectionModelChange={(newModel) => setSelectionModel(newModel)}
        checkboxSelection
        disableSelectionOnClick
        autoHeight
        rows={rowsFiltered}
        loading={loading}
        columns={columns}
        pageSize={rowsPerPage}
        rowsPerPageOptions={[10, 25, 50, 100]}
        onRowClick={(value) => onSelect(value.id as number)}
        onPageSizeChange={handleChangeRowsPerPage}
        localeText={{
          MuiTablePagination: {
            labelRowsPerPage: translateCommon(nameof.full<I18nCommonNs>((n) => n.table.rowsPerPage))
          }
        }}
        components={{
          Toolbar: () => (
            <Box paddingBottom={1}>
              <Grid container justifyContent="space-between" wrap="nowrap">
                <Grid item container spacing={1} xs={6} style={{ display: 'flex', alignItems: 'end', marginLeft: 8 }}>
                  <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                    <GridToolbarExport style={{ textAlign: 'center' }} />
                  </Grid>
                  {selectionModel && selectionModel.length > 0 ? (
                    <>
                      
                      <Grid item>
                        <Grid container spacing={1}>
                          {editingUsersCompanyId && (
                            <Grid item>
                              <Button
                                onClick={openDepartmentDialog}
                                startIcon={<HomeWork />}
                                size="small"
                                color="primary"
                                variant="text"
                                style={{ display: 'inline-flex', alignItems: 'center', gap: '4px' }}
                              >
                                <div style={{ marginBottom: -5 }}>
                                  {translate(nameof.full<I18nUserManagementNs>((n) => n.userForm.moveToDepartment))}
                                </div>


                              </Button>
                            </Grid>
                          )}
                          <Grid item>
                            <Button
                              onClick={openAdvancedSettingsDialog}
                              startIcon={<Settings />}
                              size="small"
                              color="primary"
                              variant="text"
                              style={{ display: 'inline-flex', alignItems: 'center', gap: '4px' }}
                            >
                               <div style={{ marginBottom: -5  }}>
                               {translate(nameof.full<I18nUserManagementNs>((n) => n.userForm.advancedSettings))}
                                
                                </div>
                              
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={openUsersDeleteDialog}
                          startIcon={<Delete />}
                          size="small"
                          color="primary"
                          variant="text"
                          style={{ display: 'inline-flex', alignItems: 'center', gap: '4px' }}
                        >
                          <div style={{ marginBottom: -5 }}>
                            {translate(nameof.full<I18nUserManagementNs>((n) => n.userForm.deleteUsers))}
                          </div>
                        </Button>
                      </Grid>
                    </>
                  ) : null}
                </Grid>
                <Grid item container justifyContent="flex-end" xs={6} >
                  
                </Grid>
              </Grid>
            </Box>
          )
        }}
      />
    </Box>
  );
};

export default UsersTable;
