/* eslint-disable @typescript-eslint/no-namespace */
import { createAction } from 'redux-actions';
import type {
  CreateOrganizationInvitationCommand,
  InviteMultipleUsersCommand,
  UserDto
} from '@/generated-api/index.js';
import type { LoadingStatus } from 'app/store/types.js';

/*
   This also contains expiration dates for current user managment
*/
export namespace UserActions {
  export const enum Type {
    ACCEPT_PERMISSION_FOR_RECORD = 'ACCEPT_PERMISSION_FOR_RECORD',
    GET_CURRENT_USER = 'GET_CURRENT_USER',
    SET_CURRENT_USER = 'SET_CURRENT_USER',
    SET_CURRENT_USER_LOADING_STATUS = 'SET_CURRENT_USER_LOADING_STATUS',
    SET_CURRENT_USER_NOT_FOUND = 'SET_CURRENT_USER_NOT_FOUND',
    CREATE_USER_INVITATION = 'CREATE_USER_INVITATION',
    INVITE_MULTIPLE_USERS = 'INVITE_MULTIPLE_USERS',
  }

  export const acceptPermissionForRecords = createAction<UserDto>(Type.ACCEPT_PERMISSION_FOR_RECORD);
  export const getCurrentUser = createAction(Type.GET_CURRENT_USER);
  export const setCurrentUser = createAction<UserDto>(Type.SET_CURRENT_USER);
  export const setCurrentUserLoadingStatus = createAction<LoadingStatus>(Type.SET_CURRENT_USER_LOADING_STATUS);
  export const setCurrentUserNotFound = createAction(Type.SET_CURRENT_USER_NOT_FOUND);

  export const createUserInvitation = createAction<{
    data: CreateOrganizationInvitationCommand;
    onError: () => void;
    onSuccess: () => void;
  }>(Type.CREATE_USER_INVITATION);

  export const inviteMultipleUsers = createAction<{
    data: InviteMultipleUsersCommand;
    onError: () => void;
    onSuccess: () => void;
  }>(Type.INVITE_MULTIPLE_USERS);
}