import React, { forwardRef, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  ListSubheader,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  Select,
  Switch
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { ScenarioSettingsLLM, ScenarioSettingTts } from 'app/models/scenarios/scenario.js';

import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCoreNs, I18nCommonNs, I18nScenarioEditorNs } from '@/i18n/dictionaries/interfaces.js';

import useStyles from './styles.js';
import { ScenarioSettingsLanguageVals } from '../types.js';
import type { ScenarioSettingsFormProps } from './types.js';
import { RecognitionSelector, ScenarioSelectors } from '../../../../selectors/index.js';
import type { MedicalProtocolChapterDto, MedicalProtocolDto } from '@/generated-api/index.js';
import { Sex } from '@/generated-api/index.js';
import type { SelectProps } from '@material-ui/core/Select/Select.js';
import { medicalIndexStateSelector } from 'app/store/MedicalIndex/medicalIndexSelectors.js';

import medicalIndexSlice from 'app/store/MedicalIndex/medicalIndexSlice.js';

import { LLM } from '@/generated-api/scenes/api.js';
import { TtsProvider, SttProvider } from '@/generated-api/scenes/api.js';
import type { ScenarioSettingsType } from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/hooks/useScenarioForm.js';
import { companiesSelector, currentUserCompanyMedicalIndexIds } from 'app/selectors/companiesSelector.js';
import { intersectionWith, isMatch } from 'lodash';
import type { RootState } from 'app/reducers/index.js';
import type { TTSServiceOption } from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/TTSOptions.js';

import { IntentProcessingProvider } from 'app/models/dialogs/dialogProcess.js';
import { useQuery } from '@tanstack/react-query';
import { voicesQuery } from 'app/queries/speechQueries.js';
import { isUserSuperAdminSelector } from 'app/selectors/userSelectors.js';

import { InfoOutlined, VerifiedUserOutlined } from '@material-ui/icons';
import { blue } from '@material-ui/core/colors/index.js';
import { number } from 'yup';

const sttOptions = [
  {
    name: 'Google',
    value: SttProvider.NUMBER_0
  },
  {
    name: 'Sklls',
    value: SttProvider.NUMBER_1
  }
];

const { actions } = medicalIndexSlice;

export const ScenarioGeneralSettings: React.FC<ScenarioSettingsFormProps> = ({ scenarioId }) => {
  const dispatch = useDispatch();

  const [t] = useTranslation([I18nNamespace.Core]);
  const [vt] = useTranslation(I18nNamespace.Validation);
  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const isDialogActive = useSelector(RecognitionSelector.isDialogActive);

  const renderValue = (
    selected: Pick<ScenarioSettingsType, 'sex' | 'voiceName' | 'useStreaming' | 'maxTokens'> | undefined
  ) => {
    if (!selected) {
      return '';
    }

    function sexToWord(sex: Sex, shortened?: boolean) {
      switch (sex) {
        case Sex.NUMBER_0:
          return shortened
            ? 'F'
            : translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioGeneralSettings.female));
        case Sex.NUMBER_1:
          return shortened ? 'M' : translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioGeneralSettings.male));
        default:
          return shortened ? 'M' : translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioGeneralSettings.male));
      }
    }

    const { sex, voiceName } = selected;
    
    if (!voiceName) {
      return translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioGeneralSettings.none));
    }

    const displayValue = `${voiceName} (${sexToWord(sex)})`;
    return displayValue;
  };

  const { data: allVoiceOptions } = useQuery(voicesQuery);

  useEffect(() => {
    dispatch(actions.load());
  }, [dispatch]);

  const { watch, control, errors, getValues, setValue } = useFormContext<ScenarioSettingsType>();
  const scenario = useSelector(ScenarioSelectors.getScenarioById(scenarioId));
  const isSuperAdmin = useSelector(isUserSuperAdminSelector);
  const companyId = scenario?.companyId;

  const voiceOptions = useMemo(() => {
    if (!allVoiceOptions) {
      return [];
    }
    // Only filter by companyId if one is provided
    return companyId ? allVoiceOptions.filter((v) => {
      // @ts-ignore companyId may not exist on all voice options
      const voiceCompanyId = v.companyId;
      return !voiceCompanyId || voiceCompanyId === companyId;
    }) : allVoiceOptions;
  }, [allVoiceOptions, companyId]);

  const medicalIndexes = useSelector((state: RootState) => {
    if (isSuperAdmin && (!scenario || !scenario.companyId)) {
      return medicalIndexStateSelector(state).indexes;
    }

    const medicalIndexIds =
      scenarioId != null
        ? companiesSelector(state).find((x) => x.id === companyId)?.medicalProtocols
        : currentUserCompanyMedicalIndexIds(state);

    return intersectionWith(
      medicalIndexStateSelector(state).indexes,
      medicalIndexIds,
      (medIndex, medIndex2) => medIndex.id == medIndex2.id
    );
  });
  const classes = useStyles();

  const handleChipDelete = useCallback(
    (val: number) => {
      const chapterIds = getValues(nameof.full<ScenarioSettingsType>((p) => p.medicalProtocolChapterIds)) as number[];

      const newIds = chapterIds.slice().filter((x) => x !== val);
      setValue('medicalProtocolChapterIds', newIds);
    },
    [getValues, setValue]
  );

  const [indexesSelectMenu, indexesSelectRenderValueFunc] = useMemo(() => {
    const medIndexMap: Record<
      MedicalProtocolChapterDto['id'],
      {
        chapter: MedicalProtocolChapterDto;
        medicalIndex: MedicalProtocolDto;
      }
    > = {};

    const components = medicalIndexes.reduce<React.ReactNode[]>((components, medIndex) => {
      components.push(
        <ListSubheader key={medIndex.id}>
          <Typography variant={'h6'}>{medIndex.title}</Typography>
        </ListSubheader>
      );

      const chapters = medIndex.chapters.slice().sort((a, b) => a.title.localeCompare(b.title));

      chapters.forEach((chapter) => {
        components.push(
          <MenuItem key={chapter.id} value={chapter?.id}>
            {chapter.title}
          </MenuItem>
        );
        medIndexMap[chapter.id] = {
          chapter: chapter,
          medicalIndex: medIndex
        };
      });

      return components;
    }, []);

    const renderFunction = (values: unknown) => {
      const ids = values as MedicalProtocolChapterDto['id'][] | undefined;

      return (
        <div className={classes.chips}>
          {ids?.length === 0
            ? translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioGeneralSettings.none))
            : ids?.map((value) => (
                <Chip
                  onMouseDown={(event) => {
                    event.stopPropagation();
                  }}
                  onDelete={() => handleChipDelete(value)}
                  key={value}
                  label={generateLabel(medIndexMap[value])}
                  className={classes.chip}
                  size={'small'}
                />
              ))}
        </div>
      );
    };

    function generateLabel(medIndexInfo: typeof medIndexMap[keyof typeof medIndexMap]) {
      return medIndexInfo
        ? medIndexInfo.chapter?.title
        : `[${translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioGeneralSettings.deleted))}]`;
    }

    return [components, renderFunction];
  }, [medicalIndexes, classes.chips, classes.chip, handleChipDelete]);

  const { langCode, ttsProvider } = watch(['langCode', 'ttsProvider']);

  useEffect(() => {
    if (langCode === ScenarioSettingsLanguageVals.Norwegian) {
      setValue('sttProvider', SttProvider.NUMBER_1);
    } else {
      setValue('sttProvider', SttProvider.NUMBER_0);
    }
  }, [langCode, setValue]);

  function sexToWord(sex: Sex, shortened?: boolean) {
    switch (sex) {
      case Sex.NUMBER_0:
        return shortened ? 'F' : translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioGeneralSettings.female));
      case Sex.NUMBER_1:
        return shortened ? 'M' : translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioGeneralSettings.male));
      default:
        throw new Error('Unknown Sex Enum value');
    }
  }

  const newVoiceMenuOptions = useMemo(() => {
    return (
      voiceOptions
        ?.filter((o) => o.languageCode === langCode && ttsProvider === o.ttsProvider)
        .map((option) => (
          <MenuItem
            key={String(option.sex) + (option.name ?? '')}
            value={JSON.stringify({
              [nameof.full<ScenarioSettingsType>((p) => p.sex)]: option.sex,
              [nameof.full<ScenarioSettingsType>((p) => p.voiceName)]: option.name ?? null,
            })}
          >
            {`${option.name} (${sexToWord(option.sex)})`}
          </MenuItem>
        )) ?? []
    );
  }, [langCode, ttsProvider, voiceOptions]);

  const sttMenuOptions = useMemo(() => {
    const options = sttOptions;

    return options
      .filter((so) => (langCode !== ScenarioSettingsLanguageVals.Norwegian ? so.value === SttProvider.NUMBER_0 : true))
      .map((option) => (
        <MenuItem key={nameof.full<ScenarioSettingsType>((p) => p.sex) + option.name} value={option.value}>
          {option.name}
        </MenuItem>
      ));
  }, [langCode]);

  // Handle voice selection when language or provider changes
  useEffect(() => {
    if (!voiceOptions?.length) return;

    const { langCode, sex, ttsProvider, voiceName } = getValues() as ScenarioSettingsType;

    const expectedOption: TTSServiceOption = {
      languageCode: langCode as ScenarioSettingsLanguageVals,
      sex: sex,
      ttsProvider: ttsProvider,
      name: voiceName
    };

    // If current voice is valid for the language/provider combo, keep it
    if (voiceOptions?.find((option) => isMatch(option, expectedOption))) {
      return;
    }

    const options = voiceOptions?.filter((o) => o.ttsProvider === ttsProvider && o.languageCode === langCode) ?? [];
    
    // Try to keep the same sex if possible
    const sameGenderVoices = options.filter((o) => o.sex === sex);
    if (sameGenderVoices.length > 0) {
      // Try to keep same voice name if it exists
      const sameVoice = sameGenderVoices.find((o) => o.name === voiceName);
      if (sameVoice) {
        return; // Keep current voice
      }
      // Otherwise use first voice of same gender
      setValue('voiceName', sameGenderVoices[0].name ?? null);
      return;
    }
    
    // If no voices of same gender, use first available voice or reset
    if (options.length > 0) {
      setValue('sex', options[0].sex);
      setValue('voiceName', options[0].name ?? null);
    } else {
      setValue('sex', Sex.NUMBER_0);
      setValue('voiceName', null);
    }
  }, [getValues, langCode, ttsProvider, setValue, voiceOptions]);

  const sexAndVoiceName = watch(['sex', 'voiceName']);

  return (
    <Box pt={2} height={480}>
      <Grid container>
        <Grid item xs={12}>
          <Controller
            as={TextField}
            margin="normal"
            name={nameof.full<ScenarioSettingsType>((f) => f.name)}
            disabled={isDialogActive}
            label={translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioGeneralSettings.scenarioName))}
            placeholder={translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioGeneralSettings.scenarioName))}
            control={control}
            fullWidth
            error={!!errors.name}
            helperText={errors.name?.message}
          />
          <Controller
            control={control}
            render={() => <input type="hidden" />}
            name={nameof.full<ScenarioSettingsType>((f) => f.defaultPersonaId)}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            as={TextField}
            margin="normal"
            name={nameof.full<ScenarioSettingsType>((f) => f.description)}
            disabled={isDialogActive}
            label={translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioGeneralSettings.descriptionOptional))}
            placeholder={translate(
              nameof.full<I18nScenarioEditorNs>((n) => n.scenarioGeneralSettings.descriptionOptional)
            )}
            control={control}
            fullWidth
          />
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              as={TextField}
              margin="normal"
              select
              disabled={isDialogActive}
              name={nameof.full<ScenarioSettingsType>((f) => f.langCode)}
              label={translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioGeneralSettings.language))}
              control={control}
              fullWidth
              data-cy="listOfLangueage"
              error={!!errors.langCode}
            >
              <MenuItem
                key={ScenarioSettingsLanguageVals.Norwegian}
                data-cy="Norwegian"
                value={ScenarioSettingsLanguageVals.Norwegian}
              >
                {t(nameof.full<I18nCoreNs>((l) => l.languagePicker.norwegian))}
              </MenuItem>
              <MenuItem
                key={ScenarioSettingsLanguageVals.English}
                data-cy="English (US)"
                value={ScenarioSettingsLanguageVals.English}
              >
                {t(nameof.full<I18nCoreNs>((l) => l.languagePicker.englishus))}
              </MenuItem>
              <MenuItem
                key={ScenarioSettingsLanguageVals.EnglishUk}
                data-cy="English (UK)"
                value={ScenarioSettingsLanguageVals.EnglishUk}
              >
                {t(nameof.full<I18nCoreNs>((l) => l.languagePicker.englishuk))}
              </MenuItem>
              <MenuItem
                key={ScenarioSettingsLanguageVals.German}
                data-cy="German"
                value={ScenarioSettingsLanguageVals.German}
              >
                {t(nameof.full<I18nCoreNs>((l) => l.languagePicker.german))}
              </MenuItem>
              <MenuItem
                key={ScenarioSettingsLanguageVals.Danish}
                data-cy="Danish"
                value={ScenarioSettingsLanguageVals.Danish}
              >
                {t(nameof.full<I18nCoreNs>((l) => l.languagePicker.danish))}
              </MenuItem>
              <MenuItem
                key={ScenarioSettingsLanguageVals.Swedish}
                data-cy="Swedish"
                value={ScenarioSettingsLanguageVals.Swedish}
              >
                {t(nameof.full<I18nCoreNs>((l) => l.languagePicker.swedish))}
              </MenuItem>
              <MenuItem
                key={ScenarioSettingsLanguageVals.Icelandic}
                data-cy="Icelandic"
                value={ScenarioSettingsLanguageVals.Icelandic}
              >
                {t(nameof.full<I18nCoreNs>((l) => l.languagePicker.icelandic))}
              </MenuItem>
              <MenuItem
                key={ScenarioSettingsLanguageVals.EnglishAustralia}
                data-cy="English (Australia)"
                value={ScenarioSettingsLanguageVals.EnglishAustralia}
              >
                {t(nameof.full<I18nCoreNs>((l) => l.languagePicker.englishAustralia))}
              </MenuItem>
              <MenuItem
                key={ScenarioSettingsLanguageVals.SpanishSpain}
                data-cy="Spanish (Spain)"
                value={ScenarioSettingsLanguageVals.SpanishSpain}
              >
                {t(nameof.full<I18nCoreNs>((l) => l.languagePicker.spanishSpain))}
              </MenuItem>
              <MenuItem
                key={ScenarioSettingsLanguageVals.SpanishMexico}
                data-cy="Spanish (Mexico)"
                value={ScenarioSettingsLanguageVals.SpanishMexico}
              >
                {t(nameof.full<I18nCoreNs>((l) => l.languagePicker.spanishMexico))}
              </MenuItem>
              <MenuItem
                key={ScenarioSettingsLanguageVals.SpanishUS}
                data-cy="Spanish (US)"
                value={ScenarioSettingsLanguageVals.SpanishUS}
              >
                {t(nameof.full<I18nCoreNs>((l) => l.languagePicker.spanishUs))}
              </MenuItem>
              <MenuItem
                key={ScenarioSettingsLanguageVals.ArabicUae}
                data-cy="Arabic (UAE)"
                value={ScenarioSettingsLanguageVals.ArabicUae}
              >
                {t(nameof.full<I18nCoreNs>((l) => l.languagePicker.arabicUae))}
              </MenuItem>
              <MenuItem
                key={ScenarioSettingsLanguageVals.Hindi}
                data-cy="Hindi"
                value={ScenarioSettingsLanguageVals.Hindi}
              >
                {t(nameof.full<I18nCoreNs>((l) => l.languagePicker.hindi))}
              </MenuItem>
            </Controller>
          </Grid>
          <Grid item xs={6}>
            <TextField
              margin="normal"
              onChange={(v) => {
                const { sex, voiceName } = JSON.parse(v.target.value) as Pick<
                  ScenarioSettingsType,
                  'sex' | 'voiceName'
                >;
                setValue('sex', sex);
                setValue('voiceName', voiceName ?? null);
              }}
              value={JSON.stringify(sexAndVoiceName)}
              select
              label={translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioGeneralSettings.voice))}
              disabled={isDialogActive}
              fullWidth
              SelectProps={{
                renderValue: () => renderValue(sexAndVoiceName)
              }}
            >
              {newVoiceMenuOptions}
            </TextField>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              as={TextField}
              margin="normal"
              select
              disabled={isDialogActive}
              name={nameof.full<ScenarioSettingsType>((f) => f.ttsProvider)}
              label={translate(
                nameof.full<I18nScenarioEditorNs>((n) => n.scenarioGeneralSettings.textToSpeechProvider)
              )}
              control={control}
              fullWidth
              error={!!errors.ttsProvider}
              helperText={errors.ttsProvider ? vt(errors.ttsProvider?.message as string) : '\u00A0'}
            >
              {['en-US', 'en-GB'].includes(langCode) && (
                <MenuItem key={TtsProvider.NUMBER_5} value={ScenarioSettingTts.Deepgram} data-cy="Deepgram">
                  Deepgram
                </MenuItem>
              )}
              {['en-US', 'en-GB', 'en-AU', 'de-DE', 'sv-SE', 'ar-AE', 'nb-NO', 'hi-IN'].includes(langCode) && (
                <MenuItem key={TtsProvider.NUMBER_4} value={ScenarioSettingTts.ElevenLabs} data-cy="ElevenLabs">
                  Eleven Labs
                </MenuItem>
              )}
              {!['es-MX', 'ar-AE'].includes(langCode) && (
                <MenuItem key={TtsProvider.NUMBER_1} value={ScenarioSettingTts.Google} data-cy="Google">
                  Google
                </MenuItem>
              )}
              {!['ar-AE', 'hi-IN'].includes(langCode) && (
                <MenuItem key={TtsProvider.NUMBER_3} value={ScenarioSettingTts.Microsoft} data-cy="Microsoft">
                  Microsoft
                </MenuItem>
              )}
              {['en-US', 'en-GB', 'en-AU', 'nb-NO', 'de-DE'].includes(langCode) && (
                <MenuItem key={TtsProvider.NUMBER_2} value={ScenarioSettingTts.InHouse} data-cy="Inhouse">
                  Sklls
                </MenuItem>
              )}
            </Controller>
          </Grid>

          <Grid item xs={6}>
            <Controller
              as={TextField}
              margin="normal"
              select
              disabled={!isSuperAdmin}
              name={nameof.full<ScenarioSettingsType>((f) => f.sttProvider)}
              label={translate(
                nameof.full<I18nScenarioEditorNs>((n) => n.scenarioGeneralSettings.speechToTextProvider)
              )}
              control={control}
              fullWidth
              error={!!errors.sttProvider}
              helperText={errors.sttProvider ? vt(errors.sttProvider?.message as string) : '\u00A0'}
            >
              {sttMenuOptions}
            </Controller>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <Controller
            control={control}
            disabled={isDialogActive}
            render={(props) => (
              <ChapterSelect {...props} renderValue={indexesSelectRenderValueFunc}>
                {indexesSelectMenu}
              </ChapterSelect>
            )}
            name={nameof.full<ScenarioSettingsType>((s) => s.medicalProtocolChapterIds)}
          />
        </Grid>

        {isSuperAdmin ? (
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                as={TextField}
                margin="normal"
                select
                disabled={isDialogActive}
                name={nameof.full<ScenarioSettingsType>((f) => f.llm)}
                label="LLM"
                control={control}
                fullWidth
                error={!!errors.llm}
                helperText={errors.llm ? vt(errors.llm?.message as string) : '\u00A0'}
              >
                <MenuItem key={LLM.NUMBER_2} value={ScenarioSettingsLLM.ChatGPT_4o} data-cy="ChatGPT_4o">
                  ChatGPT-4o
                </MenuItem>
                <MenuItem key={LLM.NUMBER_6} value={ScenarioSettingsLLM.Claude35Sonnet} data-cy="Claude35Sonnet">
                  Claude 3.5 Sonnet
                </MenuItem>
                <MenuItem key={LLM.NUMBER_0} value={ScenarioSettingsLLM.ChatGPT_35} data-cy="ChatGPT_35">
                  ChatGPT-3.5
                </MenuItem>
                <MenuItem key={LLM.NUMBER_13} value={ScenarioSettingsLLM.Llama3_70B} data-cy="Llama3_70B">
                  Llama 3 70B
                </MenuItem>
                <MenuItem key={LLM.NUMBER_3} value={ScenarioSettingsLLM.Claude3Haiku} data-cy="ClaudeHaiku">
                  Claude 3 Haiku
                </MenuItem>
                <MenuItem key={LLM.NUMBER_8} value={ScenarioSettingsLLM.MistralSmall} data-cy="MistralSmall">
                  Mistral Small
                </MenuItem>
                <MenuItem key={LLM.NUMBER_11} value={ScenarioSettingsLLM.DBRX} data-cy="DBRX">
                  DBRX
                </MenuItem>
              </Controller>
            </Grid>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <FormControl margin="normal">
            <FormControlLabel
              label={translate(
                nameof.full<I18nScenarioEditorNs>((n) => n.scenarioGeneralSettings.enableLargeLanguageModel)
              )}
              control={
                <Controller
                  control={control}
                  name={nameof.full<ScenarioSettingsType>((s) => s.intentProcessingProvider)}
                  render={(field) => (
                    <Switch
                      checked={field.value === IntentProcessingProvider.INTENT_RECOGNITION_WITH_AI}
                      onChange={(event) =>
                        setValue(
                          'intentProcessingProvider',
                          event.target.checked
                            ? IntentProcessingProvider.INTENT_RECOGNITION_WITH_AI
                            : IntentProcessingProvider.DEFAULT
                        )
                      }
                      name="checkedB"
                      color="primary"
                    />
                  )}
                />
              }
            />
          </FormControl>
        </Grid>
        {isSuperAdmin ? (
          <Grid item>
            <FormControlLabel
              label="Use streaming"
              control={
                <Controller
                  name={nameof.full<ScenarioSettingsType>((s) => s.useStreaming)}
                  control={control}
                  render={(field) => (
                    <Checkbox
                      checked={field.value}
                      value={field.value}
                      onChange={(e) => setValue('useStreaming', e.target.checked)}
                    />
                  )}
                />
              }
            />
          </Grid>
        ) : null}

        {isSuperAdmin ? (
          <Grid item xs={8}>
            <FormControlLabel
              label="Create shared scenario?"
              control={
                <Controller
                  name={nameof.full<ScenarioSettingsType>((s) => s.createSharedScenario)}
                  control={control}
                  render={(field) => (
                    <Checkbox
                      value={field.value}
                      onChange={(e) => setValue('createSharedScenario', e.target.checked)}
                    />
                  )}
                />
              }
            />
          </Grid>
        ) : null}

        {isSuperAdmin && (
          <Grid item xs={2}>
            <Controller
              as={TextField}
              type="number"
              margin="normal"
              name={nameof.full<ScenarioSettingsType>((f) => f.maxTokens)}
              disabled={isDialogActive}
              label="Max tokens"
              placeholder={25}
              control={control}
              fullWidth
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

const ChapterSelect = forwardRef(function ChapterSelect(props: SelectProps, ref) {
  const [translate] = useTranslation([I18nNamespace.ScenarioEditor]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  return (
    <FormControl fullWidth>
      <InputLabel shrink id="Severity-workflow-id">
        {translate(nameof.full<I18nScenarioEditorNs>((n) => n.scenarioGeneralSettings.protocolReference))}
      </InputLabel>
      <Select
        {...props}
        value={props.value ?? []}
        displayEmpty
        multiple
        inputRef={ref}
        data-cy="medicalProtocolChapter"
        // data-cy="medicalIndexChapter"
        MenuProps={{
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          transformOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          getContentAnchorEl: null
        }}
      />
    </FormControl>
  );
});
