import React, { useMemo } from 'react';
import { Dialog, DialogContent, DialogTitle, Box, Button, Grid, Typography, Switch, FormControlLabel, Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nUserManagementNs, I18nCommonNs } from '@/i18n/dictionaries/interfaces.js';
import type { GridSelectionModel } from '@material-ui/data-grid';
import { useSelector } from 'react-redux';
import { UserManagementSelectors } from 'app/selectors/index.js';
import { AccountClient } from 'app/apis/api.js';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserFormUtils } from 'app/components/UsersPage/UserForm/utils.js';
import { useMutation } from '@tanstack/react-query';
import * as UserManagementActions from 'app/actions/userManagement/userManagementActions.js';
import { useDispatch } from 'react-redux';
import { InfoOutlined } from '@material-ui/icons';

type UsersAdvancedSettingsDialogProps = {
  open: boolean;
  onClose: (action: 'submit' | 'close') => void;
  selectionModel: GridSelectionModel;
};

type AdvancedSettingsFormData = {
  hideScenarioContent: boolean;
};

const UsersAdvancedSettingsDialog: React.FC<UsersAdvancedSettingsDialogProps> = ({ open, onClose, selectionModel }) => {
  const [translate] = useTranslation([I18nNamespace.UserManagement, I18nNamespace.Common]);
  const dispatch = useDispatch();

  const users = useSelector(UserManagementSelectors.users);

  const editingUsers = useMemo(() => {
    if (!selectionModel.length) return [];
    return users
      .filter((u) => selectionModel.some((id) => id === u.id))
      .map((u) => ({
        ...u,
        hideScenarioContent: u.hideScenarioContent ?? false
      }));
  }, [users, selectionModel]);

  const schema = UserFormUtils.useEditUsersFormValidationSchema();

  const { handleSubmit, control } = useForm<AdvancedSettingsFormData>({
    defaultValues: {
      hideScenarioContent: false
    },
    resolver: yupResolver(schema)
  });

  const { mutateAsync } = useMutation({
    mutationFn: async (command: { ids: number[]; hideScenarioContent: boolean }) => {
      await AccountClient.accountUpdateUsersAdvancedSettings({
        ids: command.ids,
        hideScenarioContent: command.hideScenarioContent
      });
    },
    onSuccess: () => {
      dispatch(UserManagementActions.getUsers());
      onClose('submit');
    }
  });

  const submitForm = async (data: AdvancedSettingsFormData) => {
    await mutateAsync({
      ids: editingUsers.map((u) => u.id),
      hideScenarioContent: data.hideScenarioContent
    });
  };

  return (
    <Dialog open={open} onClose={() => onClose('close')} maxWidth="sm" fullWidth>
      <DialogTitle>
        {translate(nameof.full<I18nUserManagementNs>((n) => n.userForm.advancedSettings))}
      </DialogTitle>
      <DialogContent>
        <Box p={2}>
          <Grid
            container
            spacing={3}
            direction="column"
            component="form"
            onSubmit={handleSubmit(submitForm)}
          >
            <Grid item container>
              <Controller
                control={control}
                name="hideScenarioContent"
                render={({ value, onChange }) => (
                  <Grid container direction="column">
                    <Grid item>
                      <Box display="flex" alignItems="center">
                        <FormControlLabel
                          label={translate(
                            nameof.full<I18nUserManagementNs>((n) => n.userForm.hideScenarioContent)
                          )}
                          control={
                            <Switch
                              checked={value}
                              onChange={(event) => onChange(event.target.checked)}
                              color="primary"
                            />
                          }
                        />
                        <Tooltip
                          title={translate(
                            nameof.full<I18nUserManagementNs>((n) => n.userForm.hideScenarioContentTooltip)
                          )}
                          placement="right"
                        >
                          <InfoOutlined fontSize="small" color="action" style={{ marginLeft: '8px' }} />
                        </Tooltip>
                      </Box>
                    </Grid>
                  </Grid>
                )}
              />
            </Grid>

            <Grid item container xs={12} justifyContent="flex-end" alignItems="center">
              <Box display="flex" justifyContent="end">
                <Box mr={1}>
                  <Button type="button" color="primary" onClick={() => onClose('close')}>
                    {translate(`${I18nNamespace.Common}:${nameof.full<I18nCommonNs>((n) => n.buttonLabels.cancel)}`)}
                  </Button>
                </Box>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                >
                  {translate(`${I18nNamespace.Common}:${nameof.full<I18nCommonNs>((n) => n.buttonLabels.submit)}`)}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default UsersAdvancedSettingsDialog;
