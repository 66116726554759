import { Box, Button, Grid, Typography } from '@material-ui/core';
import React from 'react';
import useStyles from './components/Header/styles.js';
import { useMsal } from '@azure/msal-react';
import type { IPublicClientApplication, ServerError } from '@azure/msal-browser';
import { PublicClientApplication } from '@azure/msal-browser';
import { Lock } from '@material-ui/icons';
import { styled } from '@material-ui/core/styles';
import {
  loginRequest,
  guestMsalConfig,
  backendMsalConfig,
  graphLoginRequest,
  frontendMsalConfig,
  msalConfig
} from '@/authConfig.js';
import { useLocation } from 'react-router-dom';

const useExternalInstance =
  window.location.search === '?external=true' || window.location.pathname === '/loginExternal';

const MicrosoftLoginButton = (props: { onClick: () => void | Promise<void>; withMicrosoft?: boolean }) => {
  const { onClick, withMicrosoft } = props;
  return (
    <StyledButton variant="contained" color="primary" startIcon={<Lock />} onClick={onClick}>
      Sign in
    </StyledButton>
  );
};

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2),
  padding: theme.spacing(1.5, 4),
  fontSize: '1rem',
  fontWeight: 600,
  textTransform: 'none',
  borderRadius: 30,
  boxShadow: '0 3px 5px 2px rgb(248, 160, 117, .5) ',
  background: 'linear-gradient(45deg, #333333 30%, #555555 90%)',
  color: 'white',
  '&:hover': {
    background: 'linear-gradient(45deg, #222222 30%, #222222 20%)',
    boxShadow: '0 3px 5px 2px rgba(248, 160, 117)'
  }
}));

const AppUnauthenticated = () => {
  const classes = useStyles();
  const { instance } = useMsal();
  const [needsBackendConsent, setNeedsBackendConsent] = React.useState(false);

  // Capture the current requested path (excluding special routes)
  React.useEffect(() => {
    const path = window.location.pathname + window.location.search + window.location.hash;
    if (path !== '/' && path !== '/loginExternal') {
      localStorage.setItem('postLoginRedirectPath', path);
    }
  }, []);

  const loginPopupReact = async (providedInstance: IPublicClientApplication, guest = false) => {
    try {
      if (guest) {
        await providedInstance.loginPopup({ ...loginRequest, authority: guestMsalConfig.auth.authority });
      } else {
        await providedInstance.loginPopup({ ...loginRequest, authority: msalConfig.auth.authority });
      }
    } catch (e) {
      const error = e as ServerError;
      if (error.errorMessage.startsWith('AADSTS650052')) {
        setNeedsBackendConsent(true);
      }
      console.error(error);
    }
  };

  const loginFrontendPopup = async () => {
    const frontendInstance = new PublicClientApplication(useExternalInstance ? frontendMsalConfig : guestMsalConfig);
    try {
      await frontendInstance.loginPopup(graphLoginRequest);
      const redirectPath = localStorage.getItem('postLoginRedirectPath') || '/';
      localStorage.removeItem('postLoginRedirectPath');
      window.location.href = redirectPath;
    } catch (e) {
      console.error(e);
    }
  };

  const loginBackendPopup = async () => {
    const backendInstance = new PublicClientApplication(
      useExternalInstance
        ? backendMsalConfig
        : { auth: { ...backendMsalConfig.auth, authority: guestMsalConfig.auth.authority } }
    );
    try {
      await backendInstance.loginPopup(graphLoginRequest);
      const redirectPath = localStorage.getItem('postLoginRedirectPath') || '/';
      localStorage.removeItem('postLoginRedirectPath');
      window.location.href = redirectPath;
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Box height="100vh">
      <Grid container direction="column" justifyContent="center" alignItems="center" style={{ height: '70%' }}>
        <Grid item className={classes.titleLoginGridItem}>
          {/* <Typography variant="h1" className={classes.titleLogin}>
            <strong>Hi</strong>
            <i>Sklls by</i>
            <br />
          </Typography> */}
          <div className={classes.bigLogo} />
        </Grid>
        {needsBackendConsent ? (
          <>
            <Grid item>
              <Typography variant="h5" gutterBottom>
                You need to consent to additional information to use the app:
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="contained" color="secondary" onClick={() => loginBackendPopup()}>
                Sign in and Consent
              </Button>
            </Grid>
          </>
        ) : (
          <>
            {useExternalInstance ? (
              <>
                {/* <Grid item>
                  <Typography variant="h6">Work or school account:</Typography>
                </Grid> */}
                <Grid item>
                  <MicrosoftLoginButton onClick={() => loginPopupReact(instance)} withMicrosoft />
                </Grid>
                {/* <Grid item style={{ marginTop: 30 }}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => (window.location.href = '/')}
                  >
                    Go to guest login
                  </Button>
                </Grid> */}
              </>
            ) : (
              <>
                {/* <Grid item>
                  <Typography variant="h6">Sklls account:</Typography>
                </Grid> */}
                <Grid item>
                  <MicrosoftLoginButton onClick={() => loginPopupReact(instance, true)} />
                  {/* <Button variant="contained" color="secondary" onClick={() => loginPopupReact(instance, true)}>
                Sign in as Guest in Sklls Tenant
              </Button> */}
                </Grid>
                {/* <Grid item style={{ marginTop: 10 }}>
                  <Typography variant="h6">Work or school account:</Typography>
                </Grid> */}
                {/* <Grid item>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => (window.location.href = '/loginExternal')}
                  >
                    Sign in with a work or school account
                  </Button>
                </Grid> */}
              </>
            )}
          </>
        )}
      </Grid>
    </Box>
  );
};

export default AppUnauthenticated;