import type { Theme } from '@material-ui/core/styles/index.js';
import { createStyles, makeStyles } from '@material-ui/core/styles/index.js';

const userFormWidth = '30%';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    usersDeleteBtn: {
      backgroundColor: theme.palette.error.main,
      '&:hover': {
        backgroundColor: theme.palette.error.dark
      }
    },
    userFormContainer: {
      width: userFormWidth,
      minWidth: 350,
      padding: 8
    }
  })
);

export default useStyles;

// export default makeStyles({

//   usersDeleteBtn: {
//     backgroundColor: theme.
//   }
// });
