import _ from 'lodash';
import {
  Box,
  TextField,
  Button,
  Grid,
  MenuItem,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Switch,
  FormControlLabel,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import type { FieldError, SubmitHandler } from 'react-hook-form';
import { useForm, Controller } from 'react-hook-form';

import { CompanyClient } from 'app/apis/api.js';
import { RoleId } from 'app/models/users/role.js';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type UserManaged from 'app/models/userManagement/userManaged.js';
import { isUserOwnerSelector, isUserSuperAdminSelector } from 'app/selectors/userSelectors.js';
import type { UserFormData } from 'app/components/UsersPage/UserForm/types.js';
import { UserManagementSelectors, UserSelectors } from 'app/selectors/index.js';
import { UserFormUtils } from 'app/components/UsersPage/UserForm/utils.js';
import type { I18nUserManagementNs, I18nCommonNs } from '@/i18n/dictionaries/interfaces.js';
import * as UserManagementActions from 'app/actions/userManagement/userManagementActions.js';
import UserCompanyField from 'app/components/UsersPage/UserForm/UserCompanyField/UserCompanyField.js';
import UserDepartmentField from 'app/components/UsersPage/UserForm/UserDepartmentField/UserDepartmentField.js';
import type { ManagedDepartment } from 'app/components/UsersPage/UserForm/UserDepartmentField/DepartmentManager/types.js';
import type { CompanyDepartmentDto } from '@/generated-api/scenes/api.js';

import { ExpandMore, InfoOutlined } from '@material-ui/icons';

const useStyles = makeStyles({
  captionText: {
    maxWidth: '100%',
    whiteSpace: 'normal',
    wordBreak: 'normal',
    overflowWrap: 'break-word'
  }
});

type UserFormProps = {
  // when initialValues is null, form behaves like creation form
  userId: UserManaged['id'] | null;
  onClose: () => void;
};

const UserEditForm: React.FC<UserFormProps> = ({ userId, onClose }) => {
  const classes = useStyles();
  // main translate function
  const [translate] = useTranslation([I18nNamespace.UserManagement, I18nNamespace.Common]);
  // special translate function only for validation
  const [vt] = useTranslation(I18nNamespace.Validation);

  const dispatch = useDispatch();

  const users = useSelector(UserManagementSelectors.users);

  const editingUser = useMemo(() => {
    if (!userId) return null;

    const user = users.find((u) => u.id === userId);

    if (!user) return null;

    return {
      ...user,
      phone: user.phone ?? '',
      departmentId: user.departmentId ?? null,
      hideScenarioContent: user.hideScenarioContent ?? false,
      showCadForm: user.showCadForm ?? false
    };
  }, [users, userId]);

  const currentUser = useSelector(UserSelectors.CurrentUserSelector);
  const isUserOwner = useSelector(isUserOwnerSelector);
  const isSuperadmin = useSelector(isUserSuperAdminSelector);

  const defaultValues = editingUser ?? UserFormUtils.getInitialValues(currentUser);
  const schema = UserFormUtils.useUserFormValidationSchema();

  const { handleSubmit, reset, control, errors, register, setValue } = useForm<UserFormData>({
    defaultValues: defaultValues,
    resolver: yupResolver(schema)
  });

  const [departments, setDepartments] = useState<CompanyDepartmentDto[]>([]);

  const requestDepartments = useCallback(
    async (companyId: number) => {
      if (companyId == null || !companyId || !(isSuperadmin || isUserOwner)) return;
      const response = await CompanyClient.companyGetDepartments(companyId);
      if (response?.data) setDepartments(response.data);
    },
    [isSuperadmin, isUserOwner]
  );

  const onAddDepartment = async (md: ManagedDepartment) => {
    let companyId = control.getValues(nameof<UserFormData>((f) => f.companyId));
    if (!companyId) {
      companyId = editingUser.companyId;
    }
    const response = await CompanyClient.companyCreateDepartment(companyId, { name: md.name });
    setDepartments((d) => [...d, { ...response.data }]);
  };

  const onEditDepartment = async (md: ManagedDepartment) => {
    const response = await CompanyClient.companyUpdateDepartment(md.id, { name: md.name });
    setDepartments((d) => _.unionBy([response.data], d, 'id'));
  };

  const onDeleteDepartment = async (md: ManagedDepartment) => {
    await CompanyClient.companyDeleteDepartment(md.id);
    setDepartments((d) => d.filter((x) => x.id !== md.id));
  };

  useEffect(() => {
    if (!editingUser) return;
    if (!editingUser.companyId) return;

    requestDepartments(editingUser.companyId);
  }, [editingUser, requestDepartments]);

  useEffect(() => {
    if (editingUser) {
      reset(editingUser);
      register({
        name: 'id',
        value: editingUser.id
      });
    } else {
      reset(UserFormUtils.getInitialValues(currentUser));
    }
  }, [reset, editingUser, currentUser]); // register is not stable, that's why it's not it the list

  const submitEditUser = useCallback(
    (userData) => {
      dispatch(
        UserManagementActions.editUser({
          user: {
            ...userData,
            id: userId
          },
          onSuccess: () => {
            dispatch(UserManagementActions.getUsers());
            onClose();
          }
        })
      );
    },
    [dispatch, onClose, userId]
  );

  const submitAndClose: SubmitHandler<UserFormData> = (values) => {
    submitEditUser(values);
  };

  if (!userId) return null;

  return (
    <>
      <Box p={2}>
        <Grid
          container
          spacing={3}
          direction="column"
          component="form"
          onSubmit={handleSubmit(submitAndClose)}
          data-cy="createUserForm"
        >
          <Grid item>
            <Typography variant={'h6'} gutterBottom>
              {translate(nameof.full<I18nUserManagementNs>((n) => n.labels.editUser))}
            </Typography>
          </Grid>
          <Grid item data-cy="name">
            <Controller
              as={TextField}
              data-cy="nameField"
              control={control}
              name={nameof<UserFormData>((f) => f.name)}
              label={translate(nameof.full<I18nUserManagementNs>((n) => n.userForm.name))}
              type="text"
              fullWidth
            />
          </Grid>
          <Grid item data-cy="emailGrid">
            <Controller
              as={TextField}
              data-cy="emailField"
              control={control}
              name={nameof<UserFormData>((f) => f.email)}
              label={translate(nameof.full<I18nUserManagementNs>((n) => n.userForm.emails))}
              type="email"
              fullWidth
              disabled
            />
          </Grid>
          {isSuperadmin && (
            <UserCompanyField
              control={control}
              label={translate(nameof.full<I18nUserManagementNs>((n) => n.userForm.company))}
              error={!!errors.role}
              helperText={vt(((errors.role as unknown) as FieldError)?.message as string)}
            />
          )}
          <Grid item data-cy="phoneGrid">
            <Controller
              as={TextField}
              data-cy="phoneField"
              control={control}
              name={nameof<UserFormData>((f) => f.phone)}
              label={translate(nameof.full<I18nUserManagementNs>((n) => n.userForm.phone))}
              fullWidth
              error={!!errors.phone}
              helperText={vt(errors.phone?.message as string)}
            />
          </Grid>
          <Grid item data-cy="rolesSelectorGrid">
            <Controller
              as={TextField}
              data-cy="roleSelector"
              control={control}
              name={nameof<UserFormData>((f) => f.role)}
              label={translate(nameof.full<I18nUserManagementNs>((n) => n.userForm.roles))}
              fullWidth
              select
              error={!!errors.role}
              helperText={vt(((errors.role as unknown) as FieldError)?.message as string)}
            >
              <MenuItem key={RoleId.MEMBER} value={RoleId.MEMBER} data-cy={RoleId.MEMBER}>
                <div>
                  {translate(
                    `${I18nNamespace.Common}:${nameof.full<I18nCommonNs>((n) => n.userRoles)}.${RoleId.MEMBER}`
                  )}
                  <Typography variant="caption" display="block" className={classes.captionText}>
                    {translate(nameof.full<I18nUserManagementNs>((n) => n.userForm.memberDescriptrion))}
                  </Typography>
                </div>
              </MenuItem>
              <MenuItem key={RoleId.ADMIN} value={RoleId.ADMIN} data-cy={RoleId.ADMIN}>
                <div>
                  {translate(
                    `${I18nNamespace.Common}:${nameof.full<I18nCommonNs>((n) => n.userRoles)}.${RoleId.ADMIN}`
                  )}
                  <Typography variant="caption" display="block" className={classes.captionText}>
                    {translate(nameof.full<I18nUserManagementNs>((n) => n.userForm.adminDescription))}
                  </Typography>
                </div>
              </MenuItem>
              {(isUserOwner || isSuperadmin) && (
                <MenuItem key={RoleId.OWNER} value={RoleId.OWNER} data-cy={RoleId.OWNER}>
                  <div>
                    {translate(
                      `${I18nNamespace.Common}:${nameof.full<I18nCommonNs>((n) => n.userRoles)}.${RoleId.OWNER}`
                    )}
                    <Typography variant="caption" display="block" className={classes.captionText}>
                      {translate(nameof.full<I18nUserManagementNs>((n) => n.userForm.ownerDescription))}
                    </Typography>
                  </div>
                </MenuItem>
              )}
              {isSuperadmin && (
                <MenuItem key={RoleId.SUPERADMIN} value={RoleId.SUPERADMIN} data-cy={RoleId.SUPERADMIN}>
                  <div>
                    {translate(
                      `${I18nNamespace.Common}:${nameof.full<I18nCommonNs>((n) => n.userRoles)}.${RoleId.SUPERADMIN}`
                    )}
                    <Typography variant="caption" display="block" className={classes.captionText}>
                      {translate(nameof.full<I18nUserManagementNs>((n) => n.userForm.superAdminDescription))}
                    </Typography>
                  </div>
                </MenuItem>
              )}
            </Controller>
          </Grid>

          {(isUserOwner || isSuperadmin) && (
            <UserDepartmentField
              control={control}
              departments={departments}
              onAddDepartment={onAddDepartment}
              onEditDepartment={onEditDepartment}
              onDeleteDepartment={onDeleteDepartment}
              label={translate(nameof.full<I18nUserManagementNs>((n) => n.userForm.department))}
              error={!!errors.departmentId}
              helperText={vt(((errors.departmentId as unknown) as FieldError)?.message as string)}
            />
          )}

          <Grid item>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMore />}>
                <Typography>
                  {translate(nameof.full<I18nUserManagementNs>((n) => n.userForm.advancedSettings))}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container direction="column">
                  <Grid item>
                    <Box display="flex" alignItems="center">
                      <FormControlLabel
                        label={translate(nameof.full<I18nUserManagementNs>((n) => n.userForm.hideScenarioContent))}
                        control={
                          <Controller
                            control={control}
                            name={nameof<UserFormData>((f) => f.hideScenarioContent)}
                            render={(field) => (
                              <Switch
                                checked={field.value}
                                onChange={(event) => setValue('hideScenarioContent', event.target.checked)}
                                color="primary"
                              />
                            )}
                          />
                        }
                      />
                      <Tooltip
                        title={translate(
                          nameof.full<I18nUserManagementNs>((n) => n.userForm.hideScenarioContentTooltip)
                        )}
                        placement="right"
                      >
                        <InfoOutlined fontSize="small" color="action" style={{ marginLeft: '8px' }} />
                      </Tooltip>
                    </Box>
                  </Grid>
                  {/* 
                  <Grid item>
                    <FormControlLabel
                      label={translate(
                        nameof.full<I18nUserManagementNs>((n) => n.userForm.showCadForm)
                      )}
                      control={
                        <Controller
                          control={control}
                          name={nameof<UserFormData>((f) => f.showCadForm)}
                          render={(field => (
                            <Switch
                              checked={field.value}
                              onChange={(event) =>
                                setValue(
                                  'showCadForm',
                                  event.target.checked
                                )
                              }
                              color="primary"
                            />
                          ))}
                        />
                      }
                    />
                  </Grid> */}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>

          <Grid item container xs={12} justifyContent={'flex-end'} alignItems="center">
            <Box display={'flex'} justifyContent={'end'}>
              <Box mr={1}>
                <Button type="button" color="primary" onClick={onClose}>
                  {translate(`${I18nNamespace.Common}:${nameof.full<I18nCommonNs>((n) => n.buttonLabels.cancel)}`)}
                </Button>
              </Box>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                data-cy="submitCreateUserForButton"
                disabled={Object.keys(errors).length > 0}
              >
                {translate(`${I18nNamespace.Common}:${nameof.full<I18nCommonNs>((n) => n.buttonLabels.submit)}`)}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default UserEditForm;
