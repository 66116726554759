enum AppRoutes {
  Root = '/',
  Login = '/login',
  UserManagement = '/users',
  CompaniesManagement = '/companies',
  ProfilePage = '/profile',
  Introduction = '/introduction',
  Dashboard = '/dashboard',
  DashboardWithId = '/dashboard/:id',
  CourseManagement = '/courses',
  GroupManagement = '/groups',
  GlobalAssetsLibrary = '/library',
  CallManagement = '/calls',
  MedicalIndexManager = '/medical-indexes',
  Scene = '/scene',
  SceneWithId = '/scene/:id',
  MyCourses = '/my-courses',
  UserGuides = '/user-guides'
}

export enum GroupManagementRoutes {
  Root = '/groups',
  Group = '/groups/:groupId'
}

export enum SceneRoutes {
  Root = '/scene',
  Scene = '/scene/:sceneId'
}

export enum CourseManagementRoutes {
  Root = '/courses',
  Course = '/courses/:courseId',
  Schedule = '/courses/:courseId/schedule',
  ScheduledCourse = '/courses/:courseId/schedule/:scheduledCourseId',
  ScheduledCourseResults = '/courses/:courseId/schedule/:scheduledCourseId/results',
  ScheduledCourseUserGroups = '/courses/:courseId/schedule/:scheduledCourseId/user-groups',
  ScheduledCourseNotifications = '/courses/:courseId/schedule/:scheduledCourseId/notifications'
}

export enum MyCoursesRoutes {
  Root = '/my-courses',
  ScheduledCourse = '/my-courses/:scheduledCourseId'
}

export default AppRoutes;