import React from 'react';
import { List, Collapse, ListItem, ListItemText } from '@material-ui/core';

import type { SceneGroupWithScenes, SceneWithCompanyName } from './types.js';

import type { SceneDto } from '@/generated-api/scenes/api.js';
import SceneGroupContainer from 'app/components/NavigationDrawer/ScenarioSection/NavigationDrawerScenarioList/SceneGroupContainer.js';
import EditableSceneGroupListItem from 'app/components/NavigationDrawer/ScenarioSection/NavigationDrawerScenarioList/EditableSceneGroupListItem.js';
import NavigationDrawerCompanyScenariosListItem from './NavigationDrawerCompanyScenariosListItem.js';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

type NavigationDrawerCompanyScenariosProps = {
  companyId: number;
  openCompanies: number[];
  openSceneGroups: number[];
  handleClickOnSubmenu: (_key: number) => void;
  initialScene: SceneWithCompanyName;
  isShowGroupsEnabled: boolean;
  groupsWithScenes: SceneGroupWithScenes[];
  classes: {
    selected: string;
    withB: string;
    withTBP: string;
    sharedScenario: string;
    subMenuWidth: string;
    white: string;
  };
  sceneId: number;
  contextMenu: (sceneGroupId?: number) => JSX.Element;
  handleRightClick: (event: React.MouseEvent<HTMLElement>, scene: SceneDto) => void;
  handleSelectScenario: () => void;
  handleClickOnGroupSubmenu: (_key: number) => void;
  isGroupAddEnabled: boolean;
  closeAddGroup: () => void;
  onCreateSceneGroup: (sceneGroupName: any) => Promise<void>;
  scenes: SceneWithCompanyName[];
  sceneIdsInGroups: number[];
  displayCompanyName: boolean;
};

const NavigationDrawerCompanyScenarios: React.FC<NavigationDrawerCompanyScenariosProps> = ({
  companyId,
  openCompanies,
  openSceneGroups,
  handleClickOnSubmenu,
  initialScene,
  isShowGroupsEnabled,
  groupsWithScenes,
  classes,
  sceneId,
  contextMenu,
  handleRightClick,
  handleSelectScenario,
  handleClickOnGroupSubmenu,
  isGroupAddEnabled,
  closeAddGroup,
  onCreateSceneGroup,
  scenes,
  sceneIdsInGroups,
  displayCompanyName
}): JSX.Element => {

  // Recursive helper to remove empty groups
  const removeEmptyGroups = (groups: SceneGroupWithScenes[]): SceneGroupWithScenes[] => {
    return groups
      .map((g) => ({
        ...g,
        subGroups: g.subGroups ? removeEmptyGroups(g.subGroups) : []
      }))
      .filter((g) => g.scenes.length > 0 || g.subGroups.length > 0);
  };

  // Filter out empty groups
  const visibleGroups = isShowGroupsEnabled ? removeEmptyGroups(groupsWithScenes) : [];

  // Determine if this company is expanded
  const open = openCompanies.includes(companyId);

  return (
    <div>
      {displayCompanyName ? (
  <>
    <ListItem
      selected={open}
      data-cy="companyList"
      button
      onClick={() => handleClickOnSubmenu(companyId)}
    >
      <ListItemText primary={initialScene.companyName} />
      {open ? <ExpandLess /> : <ExpandMore />}
    </ListItem>
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" data-cy="listFoScnario" disablePadding>
        {isShowGroupsEnabled &&
          visibleGroups.map((sceneGroup) => (
            <SceneGroupContainer
              classes={classes}
              sceneId={sceneId}
              key={sceneGroup.id}
              sceneGroup={sceneGroup}
              openSceneGroups={openSceneGroups}
              renderContextMenu={contextMenu}
              handleRightClick={handleRightClick}
              handleSelectScenario={handleSelectScenario}
              handleClickOnGroupSubmenu={handleClickOnGroupSubmenu}
              isSubGroup={false}
            />
          ))}
        {isGroupAddEnabled && (
          <EditableSceneGroupListItem classes={classes} onClose={closeAddGroup} handleSave={onCreateSceneGroup} />
        )}
        {scenes
          .filter((x) => (isShowGroupsEnabled ? !sceneIdsInGroups.includes(x.id) : true))
          .map((item) => (
            <NavigationDrawerCompanyScenariosListItem
              item={item}
              key={item.id}
              classes={classes}
              companyId={companyId}
              handleRightClick={handleRightClick}
              handleSelectScenario={handleSelectScenario}
              sceneId={sceneId}
            />
          ))}
        {contextMenu()}
      </List>
    </Collapse>
  </>
) : (
  // If there's only one "company" or "shared scenario bucket," show the list directly.
  <List component="div" data-cy="listFoScnario" disablePadding>
    {isShowGroupsEnabled &&
      visibleGroups.map((sceneGroup) => (
        <SceneGroupContainer
          classes={classes}
          sceneId={sceneId}
          key={sceneGroup.id}
          sceneGroup={sceneGroup}
          openSceneGroups={openSceneGroups}
          renderContextMenu={contextMenu}
          handleRightClick={handleRightClick}
          handleSelectScenario={handleSelectScenario}
          handleClickOnGroupSubmenu={handleClickOnGroupSubmenu}
          isSubGroup={false}
        />
      ))}
    {isGroupAddEnabled && (
      <EditableSceneGroupListItem classes={classes} onClose={closeAddGroup} handleSave={onCreateSceneGroup} />
    )}
    {scenes
      .filter((x) => (isShowGroupsEnabled ? !sceneIdsInGroups.includes(x.id) : true))
      .map((item) => (
        <NavigationDrawerCompanyScenariosListItem
          item={item}
          key={item.id}
          classes={classes}
          companyId={companyId}
          handleRightClick={handleRightClick}
          handleSelectScenario={handleSelectScenario}
          sceneId={sceneId}
        />
      ))}
    {contextMenu()}
  </List>
)}
    </div>
  );
};

export default NavigationDrawerCompanyScenarios;