import { makeStyles } from '@material-ui/core/styles';
import type { FunctionComponent } from 'react';
import React, { useCallback, useState } from 'react';

import { KpiDetailsCard } from './KPI/kpiDetails.js';
import KPIDetailsDialog from 'app/components/DashboardPage/KPIDetails/KPIDetailsDialog.js';
import TransitionWeightsInfo from 'app/components/DashboardPage/TransitionWeights/TransitionWeightsInfo.js';
import DialogStatisticsTable from 'app/components/DashboardPage/Table/DialogStatisticsTable.js';
import { IntentDescription } from './IntentDescription/IntentDescription.js';
import type { DialogStatisticDto } from '@/generated-api/index.js';

const useStyles = makeStyles({
  layout: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  }
});

export const Dashboard: FunctionComponent = () => {
  const classes = useStyles();

  const [isKpiDetailsDialogOpen, setIsKpiDetailsDialogOpen] = useState(false);

  const [selectedDialogStatistic, setSelectedDialogStatistic] = useState<DialogStatisticDto>();

  const handleDisplayKpiDetails = useCallback((dialogStatistic: DialogStatisticDto) => {
    setSelectedDialogStatistic(dialogStatistic);
    setIsKpiDetailsDialogOpen(true);
  }, []);

  return (
    <>
      <div className={classes.layout}>
        <KpiDetailsCard dialogStatistic={selectedDialogStatistic} />
        <IntentDescription />
      </div>

      <TransitionWeightsInfo />

      <DialogStatisticsTable
        handleDisplayKpiDetails={handleDisplayKpiDetails}
        selectedDialogStatistic={selectedDialogStatistic}
        setSelectedDialogStatistic={setSelectedDialogStatistic}
      />

      <KPIDetailsDialog
        onClose={() => setIsKpiDetailsDialogOpen(false)}
        dialogStatistic={selectedDialogStatistic}
        isOpen={!!selectedDialogStatistic && isKpiDetailsDialogOpen}
      />
    </>
  );
};
