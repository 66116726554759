import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Card, CardHeader, CardContent, Tab, Tabs, Box, useTheme, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import type { DialogStatisticDto, DialogStatisticKpiDetailsDto } from '@/generated-api/index.js';
import useFormatDate from 'app/hooks/useFormatDate.js';
import { DialogStatisticsClient } from 'app/apis/api.js';
import ReachTimeScores from 'app/components/DashboardPage/KPIDetails/ReachTimeScores.js';
import TransitionScores from 'app/components/DashboardPage/KPIDetails/TransitionScores.js';
import TabPanel from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/TabPanel.js';
import ProcedureScoresTab from 'app/components/DashboardPage/KPIDetails/ProcedureScores.js';
import KIPDetailsTabHeader from 'app/components/DashboardPage/KPIDetails/KIPDetailsTabHeader.js';
import useStyles from './styles.js';
import { DateFormat } from 'app/app.constants.js';
import ConversationAnalysis from '../KPIDetails/ConversationAnalysis.js';
import { isUserSuperAdminSelector, isUserAnyAdminSelector } from 'app/selectors/userSelectors.js';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nDashboardNs } from '@/i18n/dictionaries/interfaces.js';

const defaultKpiDetails: DialogStatisticKpiDetailsDto = {
  procedureScores: [],
  reachTimeScores: [],
  transitionScores: []
};

/**
 * Fetch the main DialogStatistic object by ID,
 * so we can display top-level info.
 */
const fetchDialogStatistic = async (dialogStatisticId: number): Promise<DialogStatisticDto> => {
  const response = await DialogStatisticsClient.dialogStatisticsGetDialogStatistic(dialogStatisticId);
  return response.data;
};

/**
 * Fetch KPI details (similar to original code).
 */
const fetchKpiDetails = async (dialogStatisticId: number): Promise<DialogStatisticKpiDetailsDto> => {
  const response = await DialogStatisticsClient.dialogStatisticsGetKpiDetails(dialogStatisticId);
  return response.data;
};

export const KpiDetailsCard = (): JSX.Element => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const theme = useTheme();
  const isAnyAdmin = useSelector(isUserAnyAdminSelector);

  const [translate] = useTranslation([I18nNamespace.Dashboard]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  // We fetch the main dialog statistic for top-level data.
  const { data: dialogStatistic } = useQuery<DialogStatisticDto>({
    queryKey: ['dialogstatistic', id],
    queryFn: () => fetchDialogStatistic(Number(id)),
    enabled: Boolean(id),
  });

  // We also fetch the KPI details (scores, transitions, analysis, etc.)
  const { data: details = defaultKpiDetails } = useQuery<DialogStatisticKpiDetailsDto>({
    queryKey: ['dialogstatistic-kpi-details', id],
    queryFn: () => fetchKpiDetails(Number(id)),
    enabled: Boolean(id),
    placeholderData: defaultKpiDetails
  });

  const tabs = [
    translate(nameof.full<I18nDashboardNs>((n) => n.dialogStatisticsTable.kpiProcedureScores)),
    translate(nameof.full<I18nDashboardNs>((n) => n.dialogStatisticsTable.kpiReachTime)),
    translate(nameof.full<I18nDashboardNs>((n) => n.dialogStatisticsTable.kpiTransitionScores)),
    translate(nameof.full<I18nDashboardNs>((n) => n.kpiDetailsDialog.analysisPreview))
  ];
  const [procedureScoreTab, reachTimeScoreTab, transitionScoreTab, analysisTab] = tabs;
  const [activeTab, setActiveTab] = useState<string>(procedureScoreTab);

  return (
    <Card className={classes.root} data-cy="kpiDetails">
      <CardHeader
        title={translate(nameof.full<I18nDashboardNs>((n) => n.kpiDetails.kpiDetails))}
        titleTypographyProps={{ variant: 'h6' }}
        subheader=""
        classes={{ root: classes.header, title: classes.headerTitle }}
      />
      <CardContent className={classes.content}>
        {!dialogStatistic ? (
          <Typography align="center" variant="h5" className={classes.noData}>
            {translate(nameof.full<I18nDashboardNs>((n) => n.kpiDetails.selectaConversationToSeeStatistics))}
          </Typography>
        ) : (
          <>
            <Box marginBottom={2}>
              <Tabs
                value={activeTab}
                onChange={(_, value) => setActiveTab(value)}
                style={{ borderBottom: `1px solid ${theme.palette.divider}` }}
                scrollButtons="auto"
                variant="scrollable"
              >
                <Tab label={procedureScoreTab} value={procedureScoreTab} />
                <Tab label={reachTimeScoreTab} value={reachTimeScoreTab} />
                <Tab label={transitionScoreTab} value={transitionScoreTab} />
                {isAnyAdmin && <Tab label={analysisTab} value={analysisTab} />}
              </Tabs>
            </Box>

            <TabPanel index={procedureScoreTab} value={activeTab}>
              <KIPDetailsTabHeader>
                {`${procedureScoreTab}: ${dialogStatistic.procedureScores} / ${dialogStatistic.procedureScoresMax}`}
              </KIPDetailsTabHeader>
              <ProcedureScoresTab kpiDetails={details} />
            </TabPanel>

            <TabPanel index={reachTimeScoreTab} value={activeTab}>
              <KIPDetailsTabHeader>
                {`${reachTimeScoreTab}: ${dialogStatistic.reachTimeScores} / ${dialogStatistic.reachTimeScoresMax}`}
              </KIPDetailsTabHeader>
              <ReachTimeScores kpiDetails={details} />
            </TabPanel>

            <TabPanel index={transitionScoreTab} value={activeTab}>
              <KIPDetailsTabHeader>
                {`${transitionScoreTab}: ${dialogStatistic.transitionScores}`}
              </KIPDetailsTabHeader>
              <TransitionScores kpiDetails={details} />
            </TabPanel>

            {isAnyAdmin && (
              <TabPanel index={analysisTab} value={activeTab}>
                {activeTab === analysisTab && (
                  <ConversationAnalysis analyses={details.conversationAnalyses ?? []} />
                )}
              </TabPanel>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default KpiDetailsCard;