import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

import SceneContextFactsCard from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/SceneContextFactsCard.js';
import type { ScenarioSettingsType } from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/hooks/useScenarioForm.js';
import SceneContextPersonasCard from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/SceneContextPersonasCard.js';
import ScenarioContextLocationsCard from 'app/components/ScenarioEditorPage/ScenarioSettingsDialog/SceneContextLocationsCard.js';
import { getVariablesQuery } from 'app/queries/variableQueries.js';
import { useQuery } from '@tanstack/react-query';

type ScenarioContextSettings = {
  scenarioId?: number;
};

const ScenarioContextSettings: React.FunctionComponent<ScenarioContextSettings> = ({ scenarioId }) => {
  const name = nameof.full<ScenarioSettingsType>((c) => c.contexts);

  const { control } = useFormContext<ScenarioSettingsType>();

  const { fields } = useFieldArray({
    name,
    control,
    keyName: 'key'
  });

  const variablesQueryData = useQuery(getVariablesQuery(scenarioId));
  const variables = variablesQueryData.data ?? [];

  return (
    <Box pt={2}>
      {fields.map((field, index) => (
        <Box key={field.id}>
          <Controller
            control={control}
            defaultValue={field.id}
            render={() => <input type="hidden" />}
            name={`${name}[${index}].${nameof.full<ScenarioSettingsType>((u) => u.id)}`}
          />
          <Grid container spacing={2}>
            <Grid container item xs={6}>
              <Grid item xs={12}>
                <SceneContextFactsCard index={index} variables={variables} />
              </Grid>
            </Grid>
            <Grid item container xs={6} direction="column" spacing={2}>
              <Grid item>
                <ScenarioContextLocationsCard index={index} variables={variables} />
              </Grid>
              <Grid item>
                <SceneContextPersonasCard index={index} scenarioId={scenarioId} />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

export default ScenarioContextSettings;
