import React from 'react';
import { makeStyles } from '@material-ui/core';
import type { DataGridProps } from '@material-ui/data-grid';
import { DataGrid } from '@material-ui/data-grid';

const DEFAULT_PAGE_SIZE = 25;

const SimpleDataGrid: React.FC<DataGridProps> = ({
  pageSize = DEFAULT_PAGE_SIZE,
  rowsPerPageOptions = [DEFAULT_PAGE_SIZE],
  rows,
  onSelectionModelChange,
  selectionModel,
  ...rest
}) => {
  
  const classes = useStyles();

  return (
    <DataGrid
      classes={{
        root: classes.root
      }}
      rows={rows}
      pageSize={pageSize}
      rowsPerPageOptions={rowsPerPageOptions}
      selectionModel={selectionModel}
      onSelectionModelChange={onSelectionModelChange}
      {...rest}
    />
  );
};

const useStyles = makeStyles(() => ({
  root: {
    border: 'none',
    '& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus-within': {
      outline: 'none'
    },
    '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus': {
      outline: 'none'
    }
  }
}));

export default SimpleDataGrid;
