import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import type { FieldValues } from 'react-hook-form';
import { Controller } from 'react-hook-form';
import { Grid, MenuItem, TextField, Chip, Box } from '@material-ui/core';
import type { CompanyDepartmentDto, InviteMultipleUsersCommand, TenantDto } from '@/generated-api/index.js';
import { getAllDepartments } from 'app/actions/companyManagment/departmentActions.js';
import { departmentsSelector } from 'app/selectors/departmentSelector.js';

import { CompaniesSelector, UserSelectors } from 'app/selectors/index.js';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nUserManagementNs } from '@/i18n/dictionaries/interfaces.js';
import SimpleCompanySelectField from 'app/components/UsersPage/UserInvitation/SimpleCompanySelectField.js';

type UserInvitationFormProps = FieldValues & {
  isLoading: boolean;
  selectedCompanyId?: number | null;
  selectedDepartmentd?: number | null;
  selectedTenantId?: string | null;
  selectedTenantDomain?: string | null;
  isTenantsLoading?: boolean;
  tenants: TenantDto[];
};
const UserInvitationForm: React.FC<UserInvitationFormProps> = ({
  errors,
  control,
  selectedCompanyId,
  selectedDepartmentId
}) => {
  const [translate] = useTranslation([I18nNamespace.UserManagement]);
  const [translateValidation] = useTranslation(I18nNamespace.Validation);
  const [emailInput, setEmailInput] = React.useState('');
  const [emailChips, setEmailChips] = React.useState<string[]>([]);
  const [previewEmail, setPreviewEmail] = React.useState<string | null>(null);

  const isValidEmail = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const addEmails = (emailsToAdd: string[]) => {
    // First deduplicate the new emails
    const uniqueEmails = [...new Set(emailsToAdd.map((email) => email.trim()))];
    // Then filter for valid emails that aren't already in chips
    const validEmails = uniqueEmails.filter((email) => isValidEmail(email) && !emailChips.includes(email));
    if (validEmails.length > 0) {
      const newEmails = [...emailChips, ...validEmails];
      setEmailChips(newEmails);
      setEmailInput('');
      // Update the form control value with email list
      control.setValue(
        nameof<InviteMultipleUsersCommand>((f) => f.emails),
        newEmails
      );
    }
  };

  const addEmail = (email: string) => {
    addEmails([email]);
  };

  const processMultipleEmails = (value: string) => {
    // Split by newline, comma, or semicolon and clean up the results
    const emails = value
      .split(/[\n,;]+/)
      .map((email) => email.trim())
      .filter(Boolean);

    addEmails(emails);
    setPreviewEmail(null);
    setEmailInput('');
  };

  const handleEmailInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    // Check if input contains any separators
    if (value.match(/[\n,;]/)) {
      processMultipleEmails(value);
    } else {
      setEmailInput(value);
      // Update preview email if valid
      if (isValidEmail(value.trim()) && !emailChips.includes(value.trim())) {
        setPreviewEmail(value.trim());
      } else {
        setPreviewEmail(null);
      }
    }
  };

  const handlePaste = (event: React.ClipboardEvent) => {
    const pastedText = event.clipboardData.getData('text');
    if (pastedText.match(/[\n,;]/)) {
      event.preventDefault();
      processMultipleEmails(pastedText);
    }
  };

  const handleEmailKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && emailInput && previewEmail) {
      event.preventDefault();
      addEmail(emailInput);
      setPreviewEmail(null);
    }
  };

  const handleEmailBlur = () => {
    if (emailInput && previewEmail) {
      addEmail(emailInput);
      setPreviewEmail(null);
    }
  };

  const handleDeleteChip = (emailToDelete: string) => {
    const newEmails = emailChips.filter((email) => email !== emailToDelete);
    setEmailChips(newEmails);
    // Update the form control value
    control.setValue(
      nameof<InviteMultipleUsersCommand>((f) => f.emails),
      newEmails
    );
  };

  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch all departments once instead of per company
    dispatch(getAllDepartments());
  }, []); // Added empty dependency array to ensure it only runs once

  const companies = useSelector(CompaniesSelector.companiesSelector);
  const departments = useSelector(departmentsSelector);
  const isLoading = useSelector(CompaniesSelector.companiesAreLoadingSelector);
  const isUserSuperAdmin = useSelector(UserSelectors.isUserSuperAdminSelector);
  const isUserOwner = useSelector(UserSelectors.isUserOwnerSelector);

  const companiesDisabled = isLoading || companies.length <= 1;
  const departmentsDisabled = isLoading || !(isUserSuperAdmin || isUserOwner);

  return (
    <Grid>
      {/* Company field - shown only for SuperAdmin but always included in form data */}
      {isUserSuperAdmin ? (
        <SimpleCompanySelectField
          required
          control={control}
          error={!!errors.companyId}
          disabled={companiesDisabled}
          autoFocus={!selectedCompanyId}
          helperText={translateValidation(errors.companyId?.message as string)}
          name={nameof<InviteMultipleUsersCommand>((f) => f.companyId)}
          label={translate(nameof.full<I18nUserManagementNs>((n) => n.userForm.company))}
        />
      ) : (
        // Hidden field to maintain form data when not SuperAdmin
        <Controller
          control={control}
          name={nameof<InviteMultipleUsersCommand>((f) => f.companyId)}
          defaultValue={selectedCompanyId}
          render={() => null}
        />
      )}

      {/* Department field - shown only for SuperAdmin/Owner but always included in form data */}
      {isUserSuperAdmin || isUserOwner ? (
        <Controller
          select
          fullWidth
          type="department"
          as={TextField}
          margin="normal"
          control={control}
          error={!!errors.department}
          disabled={departmentsDisabled}
          autoFocus={!!selectedCompanyId}
          defaultValue={selectedDepartmentId}
          helperText={translateValidation(errors.departmentId?.message as string)}
          name={nameof<InviteMultipleUsersCommand>((f) => f.departmentId)}
          label={translate(nameof.full<I18nUserManagementNs>((n) => n.userForm.department))}
        >
          <MenuItem value={null}>(No department)</MenuItem>
          {departments
            .filter((department: CompanyDepartmentDto) =>
              selectedCompanyId ? department.companyId === selectedCompanyId : true
            )
            .map((department: CompanyDepartmentDto) => (
              <MenuItem key={department.id} value={department.id}>
                {department.name}
              </MenuItem>
            ))}
        </Controller>
      ) : (
        // Hidden field to maintain form data when not SuperAdmin/Owner
        <Controller
          control={control}
          name={nameof<InviteMultipleUsersCommand>((f) => f.departmentId)}
          defaultValue={selectedDepartmentId}
          render={() => null}
        />
      )}
      <Box>
        <Controller
          name={nameof<InviteMultipleUsersCommand>((f) => f.emails)}
          control={control}
          defaultValue=""
          render={() => (
            <>
              <TextField
                required
                fullWidth
                type="email"
                margin="normal"
                error={!!errors.emails}
                value={emailInput}
                onChange={handleEmailInputChange}
                onKeyDown={handleEmailKeyDown}
                onBlur={handleEmailBlur}
                onPaste={handlePaste}
                placeholder={emailChips.length === 0 ? 'email@example.com' : ''}
                autoFocus={!!selectedCompanyId}
                helperText={translateValidation(errors.emails?.message as string)}
                label={translate(nameof.full<I18nUserManagementNs>((n) => n.userForm.emails))}
                InputProps={{
                  startAdornment: emailChips.length > 0 && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        gap: '4px',
                        padding: '2px 8px 2px 2px',
                        maxWidth: 'calc(100% - 48px)', // Leave more space for input
                        alignItems: 'center',
                        minHeight: '32px'
                      }}
                    >
                      {emailChips.map((email) => (
                        <Chip
                          key={email}
                          label={email}
                          onDelete={() => handleDeleteChip(email)}
                          color="primary"
                          size="small"
                          style={{
                            height: '24px',
                            margin: '2px 0'
                          }}
                        />
                      ))}
                    </div>
                  ),
                  style: {
                    flexWrap: 'wrap',
                    padding: '8px'
                  }
                }}
              />
              {previewEmail && (
                <Box style={{ marginTop: 8, paddingLeft: 14 }}>
                  <Box
                    style={{
                      backgroundColor: '#f5f5f5',
                      borderRadius: 4,
                      padding: '8px 12px'
                    }}
                  >
                    <Box
                      style={{
                        fontSize: '0.75rem',
                        color: 'rgba(0, 0, 0, 0.54)',
                        marginBottom: 8
                      }}
                    >
                      {translate(nameof.full<I18nUserManagementNs>((n) => n.userForm.addUser))}
                    </Box>
                    <Chip
                      label={previewEmail}
                      size="small"
                      variant="outlined"
                      color="primary"
                      style={{
                        height: '24px'
                      }}
                    />
                  </Box>
                </Box>
              )}
            </>
          )}
        />
      </Box>
    </Grid>
  );
};

export default UserInvitationForm;
