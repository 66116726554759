import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, Box, Button, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nUserManagementNs, I18nCommonNs } from '@/i18n/dictionaries/interfaces.js';
import type { GridSelectionModel } from '@material-ui/data-grid';
import { useSelector } from 'react-redux';
import { isUserOwnerSelector, isUserSuperAdminSelector } from 'app/selectors/userSelectors.js';
import { UserManagementSelectors } from 'app/selectors/index.js';
import { CompanyClient, AccountClient } from 'app/apis/api.js';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserFormUtils } from 'app/components/UsersPage/UserForm/utils.js';
import type { ManagedDepartment } from 'app/components/UsersPage/UserForm/UserDepartmentField/DepartmentManager/types.js';
import type { CompanyDepartmentDto } from '@/generated-api/scenes/api.js';
import UserDepartmentField from 'app/components/UsersPage/UserForm/UserDepartmentField/UserDepartmentField.js';
import { useMutation } from '@tanstack/react-query';
import * as UserManagementActions from 'app/actions/userManagement/userManagementActions.js';
import { useDispatch } from 'react-redux';
import type { UserFormData } from 'app/components/UsersPage/UserForm/types.js';

type UsersDepartmentDialogProps = {
  open: boolean;
  onClose: (action: 'submit' | 'close') => void;
  selectionModel: GridSelectionModel;
};

type DepartmentFormData = {
  departmentId: number | null;
};

const UsersDepartmentDialog: React.FC<UsersDepartmentDialogProps> = ({ open, onClose, selectionModel }) => {
  const [translate] = useTranslation([I18nNamespace.UserManagement, I18nNamespace.Common]);
  const [vt] = useTranslation(I18nNamespace.Validation);
  const dispatch = useDispatch();

  const users = useSelector(UserManagementSelectors.users);
  const isUserOwner = useSelector(isUserOwnerSelector);
  const isSuperAdmin = useSelector(isUserSuperAdminSelector);

  const editingUsers = useMemo(() => {
    if (!selectionModel.length) return [];
    return users
      .filter((u) => selectionModel.some((id) => id === u.id))
      .map((u) => ({
        ...u,
        departmentId: u.departmentId ?? null
      }));
  }, [users, selectionModel]);

  const editingUsersCompanyId = useMemo(() => {
    if (!editingUsers.length) return null;
    const first = editingUsers[0].companyId;
    if (editingUsers.every((u) => u.companyId === first)) return first;
    return null;
  }, [editingUsers]);

  const [departments, setDepartments] = useState<CompanyDepartmentDto[]>([]);

  const schema = UserFormUtils.useEditUsersFormValidationSchema();

  const defaultDepartmentId = useMemo(() => {
    if (!editingUsers.length) return null;
    const firstDepartmentId = editingUsers[0].departmentId;
    return editingUsers.every(user => user.departmentId === firstDepartmentId) ? firstDepartmentId : null;
  }, [editingUsers]);

  const { handleSubmit, control, errors } = useForm<DepartmentFormData>({
    defaultValues: {
      departmentId: defaultDepartmentId
    },
    resolver: yupResolver(schema)
  });

  const { mutateAsync } = useMutation({
    mutationFn: async (command: { ids: number[]; departmentId: number | null }) => {
      await AccountClient.accountUpdateUsersDepartment({
        ids: command.ids,
        departmentId: command.departmentId
      });
    },
    onSuccess: () => {
      dispatch(UserManagementActions.getUsers());
      onClose('submit');
    }
  });

  const requestDepartments = useCallback(
    async (companyId: number) => {
      if (companyId == null || !companyId || !(isSuperAdmin || isUserOwner)) return;
      const response = await CompanyClient.companyGetDepartments(companyId);
      if (response?.data) setDepartments(response.data);
    },
    [isSuperAdmin, isUserOwner]
  );

  const onAddDepartment = async (md: ManagedDepartment) => {
    if (editingUsersCompanyId) {
      const response = await CompanyClient.companyCreateDepartment(editingUsersCompanyId, { name: md.name });
      setDepartments((d) => [...d, { ...response.data }]);
    }
  };

  const onEditDepartment = async (md: ManagedDepartment) => {
    const response = await CompanyClient.companyUpdateDepartment(md.id, { name: md.name });
    setDepartments((d) => d.filter((x) => x.id !== md.id).concat(response.data));
  };

  const onDeleteDepartment = async (md: ManagedDepartment) => {
    await CompanyClient.companyDeleteDepartment(md.id);
    setDepartments((d) => d.filter((x) => x.id !== md.id));
  };

  useEffect(() => {
    if (!editingUsersCompanyId) return;
    requestDepartments(editingUsersCompanyId);
  }, [editingUsersCompanyId, requestDepartments]);

  const submitForm = async (data: DepartmentFormData) => {
    await mutateAsync({
      ids: editingUsers.map((u) => u.id),
      departmentId: data.departmentId
    });
  };

  return (
    <Dialog open={open} onClose={() => onClose('close')} maxWidth="sm" fullWidth>
      <DialogTitle>
        {translate(nameof.full<I18nUserManagementNs>((n) => n.userForm.department))}
      </DialogTitle>
      <DialogContent>
        <Box p={2}>
          <Grid
            container
            spacing={3}
            direction="column"
            component="form"
            onSubmit={handleSubmit(submitForm)}
          >
            {(isUserOwner || isSuperAdmin) ? (
              <Grid item>
                <UserDepartmentField
                  control={control}
                  departments={departments}
                  onAddDepartment={onAddDepartment}
                  onEditDepartment={onEditDepartment}
                  onDeleteDepartment={onDeleteDepartment}
                  label={translate(nameof.full<I18nUserManagementNs>((n) => n.userForm.department))}
                  error={!!errors.departmentId}
                  helperText={vt(((errors.departmentId as unknown) as { message: string })?.message)}
                />
              </Grid>
            ) : null}

            <Grid item container xs={12} justifyContent="flex-end" alignItems="center">
              <Box display="flex" justifyContent="end">
                <Box mr={1}>
                  <Button type="button" color="primary" onClick={() => onClose('close')}>
                    {translate(`${I18nNamespace.Common}:${nameof.full<I18nCommonNs>((n) => n.buttonLabels.cancel)}`)}
                  </Button>
                </Box>
                {(isUserOwner || isSuperAdmin) && (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={Object.keys(errors).length > 0}
                  >
                    {translate(`${I18nNamespace.Common}:${nameof.full<I18nCommonNs>((n) => n.buttonLabels.submit)}`)}
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default UsersDepartmentDialog;
