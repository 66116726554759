import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import React, { useCallback, useEffect, useState } from 'react';
import type { InviteMultipleUsersCommand } from '@/generated-api/index.js';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

import { LoadingStatus } from 'app/store/types.js';
import { UserActions } from 'app/actions/user/userAction.js';
import { CompaniesSelector, UserSelectors } from 'app/selectors/index.js';
import UserInvitationForm from 'app/components/UsersPage/UserInvitation/UserInvitationForm.js';
import { CompanyManagementActions } from 'app/actions/companyManagment/companyManagementActions.js';
import { useQuery } from '@tanstack/react-query';
import { tenantsQuery } from 'app/queries/entraQueries.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nUserManagementNs } from '@/i18n/dictionaries/interfaces.js';

type CreateUserInvitationDialogProps = {
  open: boolean;
  onClose: () => void;
};

const defaultValues: InviteMultipleUsersCommand = {
  emails: [],
  companyId: null,
  departmentId: null,
  tenantDomain: '',
  tenantId: ''
};

const schema = yup.object<InviteMultipleUsersCommand>({
  companyId: yup.number().required(),
  emails: yup.array().of(yup.string().email()).required().min(1, 'At least one email is required')
});

const UserInvitationDialog: React.FC<CreateUserInvitationDialogProps> = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(UserSelectors.CurrentUserSelector);
  const isUserSuperAdmin = useSelector(UserSelectors.isUserSuperAdminSelector);

  useEffect(() => {
    dispatch(CompanyManagementActions.getCompanies());
  }, [dispatch]);

  const [translate] = useTranslation([I18nNamespace.UserManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.Idle);

  const { handleSubmit, reset, control, errors, watch } = useForm<InviteMultipleUsersCommand>({
    defaultValues: {
      ...defaultValues,
      companyId: currentUser?.companyId,
      departmentId: currentUser?.departmentId
    },
    resolver: yupResolver(schema)
  });

  const closeDialog = useCallback(() => {
    onClose();
    reset();
    setLoadingStatus(LoadingStatus.Idle);
  }, [reset, onClose]);

  const onSuccess = useCallback(() => {
    setLoadingStatus(LoadingStatus.Succeeded);
    closeDialog();
  }, [closeDialog]);

  const onError = useCallback(() => {
    setLoadingStatus(LoadingStatus.Failed);
  }, []);

  const submitAndClose: SubmitHandler<InviteMultipleUsersCommand> = (data) => {
    setLoadingStatus(LoadingStatus.Loading);

    dispatch(
      UserActions.inviteMultipleUsers({
        data,
        onError,
        onSuccess
      })
    );
  };

  const {
    companyId: selectedCompanyId,
    departmentId: selectedDepartmentId,
    tenantId: selectedTenantId,
    tenantDomain: selectedTenantDomain
  } = watch();

  const companies = useSelector(CompaniesSelector.companiesSelector);
  const isLoading = useSelector(CompaniesSelector.companiesAreLoadingSelector);

  const tenants = useQuery({ ...tenantsQuery, enabled: isUserSuperAdmin });

  return (
    <Dialog open={open} onClose={closeDialog} maxWidth={'sm'} fullWidth>
      <form
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault(); // Prevent form submission on Enter
          }
        }}
      >
        <DialogTitle>{translate(nameof.full<I18nUserManagementNs>((n) => n.inviteUsers))}</DialogTitle>
        <DialogContent>
          <UserInvitationForm
            errors={errors}
            control={control}
            companies={companies}
            isLoading={isLoading}
            selectedCompanyId={selectedCompanyId}
            selectedDepartmentId={selectedDepartmentId}
            selectedTenantDomain={selectedTenantDomain}
            selectedTenantId={selectedTenantId}
            tenants={tenants.data ?? []}
            isTenantsLoading={tenants?.isLoading}
          />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={closeDialog}>
            {translateCommon(`${I18nNamespace.Common}:${nameof.full<I18nCommonNs>((n) => n.buttonLabels.cancel)}`)}
          </Button>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleSubmit(submitAndClose)}
            disabled={!!Object.keys(errors).length || loadingStatus === LoadingStatus.Loading}
          >
            {translateCommon(`${I18nNamespace.Common}:${nameof.full<I18nCommonNs>((n) => n.buttonLabels.submit)}`)}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UserInvitationDialog;
