import { takeLatest, fork, put, select, race, take, delay } from 'redux-saga/effects';
import type { Action } from 'redux-actions';

import { statisticActions } from '../../../actions/statistics/statisticActions.js';
import { postDialogStatistics } from 'app/actions/dasboard/dashboardActions.js';
import type { AddDialogStatisticCommand } from '@/generated-api/index.js';
import * as DialogProcessorSelectors from 'app/selectors/dialogProcessorSelector.js';
import { DialogProcessorActions } from 'app/actions/dialogprocessor/dialogProcessorActions.js';
import { ScenarioChartStoreActions } from 'app/actions/scenarios/scenarioChartStoreAction.js';
import logger from 'app/utils/logger.js';

function* stopStatistic(params: Action<AddDialogStatisticCommand>) {
  const sessionId = yield select(DialogProcessorSelectors.sessionIdSelector);

  try {
    yield put(
      postDialogStatistics({
        sessionId,
        ...params.payload
      })
    );

    // Use a race to wait for success or failure 
    const { success, failure } = yield race({
      success: take(statisticActions.Type.POST_DIALOG_STATISTICS_SUCCESS),
      failure: take(statisticActions.Type.POST_DIALOG_STATISTICS_FAILURE),
      timeout: delay(10000)
    });

    // Once stats are posted (or fail), we can reset
    yield put(ScenarioChartStoreActions.resetIsDialogProcessOnTheNode());

    // If success or failure or even after a timeout, we still reset sessionId
    // so we don't leave it stuck in state
    yield put(DialogProcessorActions.setSessionId(null));

    if (failure) {
      logger.warn('Dialog statistics post failed. Session was reset anyway.');
    }
  } catch (error) {
    logger.log('Error getDPResponse in stopStatistic: ', error);
  }
}

function* watchPostStatistic() {
  yield takeLatest(statisticActions.Type.POST_STATISTICS, stopStatistic);
}

export default function* watchAllStatisticSaga() {
  yield fork(watchPostStatistic);
}
