import React, { useCallback, useMemo } from 'react';
import { DialogContentText } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingStatus } from 'app/store/types.js';
import { UserManagementSelectors } from 'app/selectors/index.js';
import type UserManaged from 'app/models/userManagement/userManaged.js';
import * as UserManagementActions from 'app/actions/userManagement/userManagementActions.js';
import DeleteConfirmationDialog from 'app/components/UsersPage/UserDelete/DeleteConfirmationDialog.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nUserManagementNs } from '@/i18n/dictionaries/interfaces.js';
import { useMutation } from '@tanstack/react-query';
import { UserClient } from 'app/apis/api.js';
import type { GridSelectionModel } from '@material-ui/data-grid';

type UserDeleteDialogProps = {
  selectionModel: GridSelectionModel;
  open: boolean;
  onClose: () => void;
};

const UsersDeleteDialog: React.FC<UserDeleteDialogProps> = ({ selectionModel, open, onClose }) => {
  const dispatch = useDispatch();

  const [translate] = useTranslation([I18nNamespace.UserManagement]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const userIds = useMemo(() => {
    return selectionModel.map((id) => Number(id)).filter((id) => Number.isInteger(id));
  }, [selectionModel]);

  const { mutateAsync } = useMutation({
    mutationFn: async (userIds: number[]) => {
      await UserClient.userDeleteUsers(userIds);
    },
    onSuccess: () => {
      // refetch users?
      dispatch(UserManagementActions.getUsers());
      onClose();
    }
  });

  // const onDeleteUser = useCallback(
  //   (id: number) => {
  //     dispatch(UserManagementActions.deleteUser({ id, onSuccess: onClose }));
  //   },
  //   [onClose, dispatch]
  // );

  const isLoading = !useSelector(UserManagementSelectors.usersLoaded);

  const onConfirmDeleteUser = useCallback(() => mutateAsync(userIds), [userIds, mutateAsync]);

  return (
    <DeleteConfirmationDialog
      open={open}
      onClose={onClose}
      title={translate(nameof.full<I18nUserManagementNs>((n) => n.labels.deleteUser))}
      confirmationText={translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.delete))}
      onConfirm={onConfirmDeleteUser}
      loadingStatus={isLoading ? LoadingStatus.Loading : LoadingStatus.Idle}
    >
      <DialogContentText>
        {translate(nameof.full<I18nUserManagementNs>((n) => n.deleteUser.confirmationText))}{' '}
        <b>{`${userIds.length} ${translate(
          nameof.full<I18nUserManagementNs>((n) => n.labels.users)
        ).toLowerCase()}`}</b>
        ? {translate(nameof.full<I18nUserManagementNs>((n) => n.deleteUser.cannotBeUndone))}
      </DialogContentText>
    </DeleteConfirmationDialog>
  );
};

export default UsersDeleteDialog;
