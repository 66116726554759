// src/components/NavigationDrawer/ScenarioSection/ScenarioListItem.tsx

import React from 'react';
import { ListItem, ListItemIcon, ListItemText, Tooltip, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { ControlCamera } from '@material-ui/icons';
import clsx from 'clsx';
import { generatePath } from 'react-router';
import AppRoutes from '../../../utils/routes.js';
import truncateString from './utils/helpers.js';
import { MENU_ITEM_MAX_CHAR_LENGTH } from './NavigationDrawerScenarioList/constants.js';
import { useSelector } from 'react-redux';
import { isUserAnyAdminSelector } from '../../../selectors/userSelectors.js';
import type { SceneDto } from '@/generated-api/scenes/api.js';

const useStyles = makeStyles((theme) => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(14)
  }
}));

interface ScenarioListItemProps {
  scene: SceneDto;
  classes: {
    selected: string;
    withB: string;
    withTBP: string;
    sharedScenario: string;
    subMenuWidth: string;
    white: string;
    nested?: string;
    subGroupSubMenuWidth?: string;
    rootItem?: string;
  };
  companyId?: number;
  handleRightClick: (event: React.MouseEvent<HTMLElement>, scene: SceneDto) => void;
  handleSelectScenario: () => void;
  isSelected: boolean;
  isSubGroupItem?: boolean;
  disableActions?: boolean;
}

const ScenarioListItem: React.FC<ScenarioListItemProps> = ({
  scene,
  classes,
  companyId,
  handleRightClick,
  handleSelectScenario,
  isSelected,
  isSubGroupItem,
  disableActions
}) => {
  const isAnyAdmin = useSelector(isUserAnyAdminSelector);
  const path = scene.id ? generatePath(AppRoutes.SceneWithId, { id: scene.id }) : '#';
  const tooltipClasses = useStyles();

  const listItemContent = (
    <ListItem
      key={companyId && scene.id ? companyId + scene.id : scene.id}
      onContextMenu={
        disableActions ? undefined : (event: React.MouseEvent<HTMLElement>) => handleRightClick(event, scene)
      }
      data-cy="scenario"
      onClick={handleSelectScenario}
      to={path}
      classes={{ selected: classes.selected }}
      className={clsx(
        classes.withB,
        classes.withTBP,
        isSubGroupItem ? classes.nested : classes.rootItem,
        !scene.companyId && classes.sharedScenario
      )}
      component={Link}
      button
      selected={isSelected}
    >
      <ListItemIcon className={isSubGroupItem ? classes.subGroupSubMenuWidth : classes.subMenuWidth}>
        <ControlCamera
          classes={{ root: classes.white }}
          className={isSubGroupItem ? classes.subGroupSubMenuWidth : classes.subMenuWidth}
        />
      </ListItemIcon>
      <ListItemText primary={truncateString(scene.name || '', MENU_ITEM_MAX_CHAR_LENGTH)} />
    </ListItem>
  );

  return isAnyAdmin && scene.description ? (
    <Tooltip title={scene.description} classes={{ tooltip: tooltipClasses.tooltip }}>
      {listItemContent}
    </Tooltip>
  ) : (
    listItemContent
  );
};

export default ScenarioListItem;
