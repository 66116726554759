import { Grid, TextField } from '@material-ui/core';
import type { FC } from 'react';
import React from 'react';
import useStyles from './kpiFiltersStyle.js';

import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs, I18nDashboardNs } from '@/i18n/dictionaries/interfaces.js';
import { format } from 'date-fns';

interface KpiStatisticsFiltersProps {
  timeFrom: Date | null;
  onSetTimeFrom: (date: Date) => void;
  timeTo: Date | null;
  onSetTimeTo: (date: Date) => void;
  setSearch: (search: string) => void;
  search: string | undefined;
}

const convertDate = (dateStr: string | null, endOf = false) => {
  if (!dateStr) return null;

  const date = new Date(dateStr);

  if (endOf) {
    date.setHours(23, 59, 59, 999);
  } else {
    date.setHours(0);
  }

  return date;
};

const KpiStatisticsFilters: FC<KpiStatisticsFiltersProps> = ({
  search,
  setSearch,
  timeFrom,
  onSetTimeFrom,
  timeTo,
  onSetTimeTo
}) => {
  const classes = useStyles();

  const [translate] = useTranslation([I18nNamespace.Dashboard]);
  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  return (
    <Grid container className={classes.container}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item className={classes.rowContainer}>
          <Grid className={classes.datePickerContainer}>
            <TextField
              type="date"
              value={timeFrom ? format(timeFrom, 'yyyy-MM-dd') : ''}
              onChange={(event) => onSetTimeFrom(convertDate(event.target.value))}
              label={translate(nameof.full<I18nDashboardNs>((n) => n.kpiStatisticsFilters.fromDate))}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              size="small"
            />
          </Grid>
          <Grid className={classes.datePickerContainer}>
            <TextField
              type="date"
              value={timeTo ? format(timeTo, 'yyyy-MM-dd') : ''}
              onChange={(event) => onSetTimeTo(convertDate(event.target.value, true))}
              label={translate(nameof.full<I18nDashboardNs>((n) => n.kpiStatisticsFilters.toDate))}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              size="small"
            />
          </Grid>
        </Grid>
        <Grid item>
          <TextField
            type=""
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            placeholder={translate(nameof.full<I18nDashboardNs>((n) => n.kpiStatisticsFilters.search))}
            variant="outlined"
            inputProps={{
              className: classes.searchInput
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default KpiStatisticsFilters;
