import clsx from 'clsx';
import type { FC } from 'react';
import React, { useCallback } from 'react';
import { ListItem, ListItemText, ListItemIcon, Menu, MenuItem, Fade } from '@material-ui/core';

import type { ClassNameMap } from '@material-ui/core/styles/withStyles.js';

import { SceneClient } from 'app/apis/api.js';
import { useBoolState } from 'app/utils/customHooks/index.js';
import useCursorPosition from 'app/components/NavigationDrawer/hooks/useCursorPosition.js';
import truncateString from 'app/components/NavigationDrawer/ScenarioSection/utils/helpers.js';
import EditableSceneGroupListItem from 'app/components/NavigationDrawer/ScenarioSection/NavigationDrawerScenarioList/EditableSceneGroupListItem.js';
import { MENU_ITEM_MAX_CHAR_LENGTH } from 'app/components/NavigationDrawer/ScenarioSection/NavigationDrawerScenarioList/constants.js';
import { useQueryClient } from '@tanstack/react-query';
import { getScenarioGroupsQuery } from 'app/queries/scenarioQueries.js';
import type { SceneGroupWithScenes } from './types.js';
import { Folder, ExpandLess, ExpandMore } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { I18nNamespace } from '@/i18n/types/i18nNamespace.js';
import type { I18nCommonNs } from '@/i18n/dictionaries/interfaces.js';

export interface SceneGroupListItemProps {
  classes: ClassNameMap;
  sceneGroup: SceneGroupWithScenes;
  openSceneGroups?: number[];
  handleClickOnGroupSubmenu: (sceneGroupId: number) => void;
  isSubGroup?: boolean;
}

const SceneGroupListItem: FC<SceneGroupListItemProps> = ({
  classes,
  sceneGroup,
  openSceneGroups,
  handleClickOnGroupSubmenu,
  isSubGroup
}): JSX.Element => {
  const anchorElRef = React.useRef<HTMLDivElement>(null);
  const queryClient = useQueryClient();

  const [shouldDisplayContextMenu, displayContextMenu, hideContextMenu] = useBoolState();
  const [isEditing, setIsEditingTrue, setIsEditingFalse] = useBoolState();

  const { cursorPosition, setCursorPosition, resetCursorPosition } = useCursorPosition();

  const [translateCommon] = useTranslation([I18nNamespace.Common]);

  const onContextMenu = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      displayContextMenu();

      setCursorPosition({
        x: event.clientX - 2,
        y: event.clientY - 4
      });
    },
    [displayContextMenu, setCursorPosition]
  );

  const onDeleteSceneGroup = useCallback(
    async (event) => {
      event.stopPropagation();
      hideContextMenu();
      resetCursorPosition();

      await SceneClient.sceneDeleteGroup(sceneGroup.id);
      queryClient.invalidateQueries(getScenarioGroupsQuery.queryKey);
    },
    [hideContextMenu, resetCursorPosition, sceneGroup.id, queryClient]
  );

  const renameSceneGroup = useCallback(
    (event) => {
      event.stopPropagation();
      setIsEditingTrue();
    },
    [setIsEditingTrue]
  );

  const resetEditGroupName = useCallback(() => {
    hideContextMenu();
    resetCursorPosition();
    setIsEditingFalse();
  }, [hideContextMenu, setIsEditingFalse, resetCursorPosition]);

  const onSaveSceneGroup = useCallback(
    async (sceneGroupName: string) => {
      await SceneClient.sceneUpdateGroup(sceneGroup.id, { ...sceneGroup, title: sceneGroupName });

      queryClient.invalidateQueries(getScenarioGroupsQuery.queryKey);

      resetEditGroupName();
    },
    [sceneGroup, queryClient, resetEditGroupName]
  );

  if (isEditing) {
    return (
      <EditableSceneGroupListItem
        classes={classes}
        onClose={resetEditGroupName}
        handleSave={onSaveSceneGroup}
        defaultValue={sceneGroup.title}
      />
    );
  }

  return (
    <ListItem
      button
      ref={anchorElRef}
      data-cy="sceneGroupList"
      onContextMenu={sceneGroup.disableActions ? undefined : onContextMenu}
      selected={false}
      classes={{ selected: classes.selected }}
      className={clsx(classes.withB, classes.withTBP, classes.folderItem)}
      onClick={() => handleClickOnGroupSubmenu(sceneGroup.id)}
    >
      <ListItemIcon className={isSubGroup ? classes.subGroupSubMenuWidth : classes.subMenuWidth}>
        <Folder
          classes={{ root: classes.white }}
          className={isSubGroup ? classes.subGroupSubMenuWidth : classes.subMenuWidth}
        />
      </ListItemIcon>
      <ListItemText primary={truncateString(sceneGroup.title, MENU_ITEM_MAX_CHAR_LENGTH)} />
      {openSceneGroups && openSceneGroups.includes(sceneGroup.id) ? <ExpandLess /> : <ExpandMore />}
      <Menu
        keepMounted
        onClose={hideContextMenu}
        TransitionComponent={Fade}
        open={!sceneGroup.disableActions && shouldDisplayContextMenu}
        anchorReference="anchorPosition"
        anchorPosition={
          cursorPosition.y !== null && cursorPosition.x !== null
            ? { top: cursorPosition.y, left: cursorPosition.x }
            : undefined
        }
        PaperProps={{
          onMouseLeave: hideContextMenu
        }}
      >
        <MenuItem onClick={onDeleteSceneGroup}>
          {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.delete))}
        </MenuItem>
        <MenuItem onClick={renameSceneGroup}>
          {translateCommon(nameof.full<I18nCommonNs>((n) => n.buttonLabels.rename))}
        </MenuItem>
      </Menu>
    </ListItem>
  );
};

export default SceneGroupListItem;